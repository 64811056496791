import * as React from "react";
import Box from "@mui/material/Box";
import Collapse from "@mui/material/Collapse";
import IconButton from "@mui/material/IconButton";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Typography from "@mui/material/Typography";
import Paper from "@mui/material/Paper";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import { Button, styled, tableCellClasses } from "@mui/material";
import { Venda } from "../types";
import dayjs from "dayjs";
import DirectionsCarIcon from "@mui/icons-material/DirectionsCar";
import { toNumber, toReais } from "../Services/AuxiliarFunctions";
import { Link, useNavigate } from "react-router-dom";
import DeleteIcon from "@mui/icons-material/Delete";
import { addVehicle, deleteVenda } from "../Services/FirebaseDatabase";
import { useDispatch } from "react-redux";
import { AppDispatch } from "../Store";
import { fetchVehicles, fetchVendas } from "../Store/Veículos";

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: localStorage.getItem("color"),
    color: theme.palette.common.white,
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
  },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  "&:nth-of-type(odd)": {
    backgroundColor: theme.palette.action.hover,
  },
  // hide last border
  "&:last-child td, &:last-child th": {
    border: 0,
  },
}));

interface TableVendasRowProps {
  venda: Venda;
}

function Row(props: TableVendasRowProps) {
  const [open, setOpen] = React.useState(false);
  const dispatch = useDispatch<AppDispatch>();
  const navigate = useNavigate();
  const [loading, setLoading] = React.useState("");

  function calcLucro() {
    let somaDespesas = 0;
    for (let key in props.venda.vehicle.despesas) {
      somaDespesas += Number(props.venda.vehicle.despesas[key].valor);
    }
    somaDespesas += toNumber(props.venda.vehicle.valorCompra);
    let faturamento = Number(props.venda.valor);

    if (props.venda.ValorCompraTroca) {
      faturamento += Number(props.venda.ValorCompraTroca);
    }
    return faturamento - somaDespesas;
  }

  async function restaurarVenda() {
    if (
      confirm(
        "Deseja realmente excluir a venda e recadastrar o veículo no estoque? \n" +
          props.venda.vehicle.fipeInfo.model
      )
    ) {
      setLoading("Restaurando Veículo...");
      await addVehicle(props.venda.vehicle);
      setLoading("Deletando Venda");
      await deleteVenda(props.venda.comprador, props.venda.data);
      await dispatch(fetchVehicles());
      await dispatch(fetchVendas());
      navigate("/cars");
    }
  }

  return (
    <React.Fragment>
      <TableRow sx={{ "& > *": { borderBottom: "unset" } }}>
        <TableCell>
          <IconButton
            aria-label="expand row"
            size="small"
            onClick={() => setOpen(!open)}
          >
            {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
          </IconButton>
        </TableCell>
        <TableCell component="th" scope="row">
          {props.venda.vehicle.fipeInfo.model}
        </TableCell>
        <TableCell>{props.venda.comprador}</TableCell>
        <TableCell>{dayjs(props.venda.data).format("DD/MM/YYYY")}</TableCell>
        <TableCell>
          <Typography my={1} variant="body1">
            <span style={{ color: props.venda.lucro > 0 ? "green" : "red" }}>
              {toReais(calcLucro())}
            </span>
          </Typography>
        </TableCell>
      </TableRow>
      <TableRow>
        <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={6}>
          <Collapse in={open} timeout="auto" unmountOnExit>
            <Box sx={{ margin: 1 }}>
              <Typography variant="h6" gutterBottom component="div">
                Detalhes
              </Typography>
              <Table size="small" aria-label="purchases">
                <TableHead>
                  <TableRow>
                    <TableCell>
                      <Typography variant="h5">
                        {props.venda.descriçãoTroca
                          ? "Carro recebido: " + props.venda.descriçãoTroca
                          : "Não foi recebido troca."}
                      </Typography>
                      <Typography my={1} variant="h6">
                        {props.venda.descriçãoTroca
                          ? "Avaliado em:  " +
                            toReais(Number(props.venda.ValorCompraTroca))
                          : "Pagamento no valor de: " +
                            toReais(Number(props.venda.valor))}
                      </Typography>
                      <Typography my={1} variant="h6">
                        {props.venda.descriçãoTroca
                          ? "+ Pagamento no valor de: " +
                            toReais(Number(props.venda.valor))
                          : ""}
                      </Typography>
                    </TableCell>

                    <Link to={`/cars/sold/${props.venda.vehicle.placa}`}>
                      <IconButton>
                        <DirectionsCarIcon fontSize="large" color="primary" />
                      </IconButton>
                    </Link>
                    <IconButton
                      onClick={restaurarVenda}
                      sx={{ marginLeft: "10px" }}
                    >
                      <DeleteIcon fontSize="large" color="primary" />
                    </IconButton>
                    <Typography>{loading && loading}</Typography>
                  </TableRow>
                </TableHead>
              </Table>
            </Box>
          </Collapse>
        </TableCell>
      </TableRow>
    </React.Fragment>
  );
}

interface TableVendasProps {
  vendas: Venda[];
}

export default function TableVendas(props: TableVendasProps) {
  return (
    <TableContainer component={Paper}>
      <Table aria-label="collapsible table">
        <TableHead>
          <StyledTableRow>
            <StyledTableCell>Infos</StyledTableCell>
            <StyledTableCell>Veículo</StyledTableCell>
            <StyledTableCell>Comprador</StyledTableCell>
            <StyledTableCell>Data</StyledTableCell>
            <StyledTableCell>Lucro Estimado</StyledTableCell>
          </StyledTableRow>
        </TableHead>
        <TableBody>
          {props.vendas.map((venda) => (
            <Row venda={venda} />
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
}

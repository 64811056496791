import React, { useEffect, useState } from "react";
import Appbar from "../../Components/Appbar";
import Content from "../../Components/Content";
import { useSelector } from "react-redux";
import type { AppDispatch, RootState } from "../../Store/index";
import { useParams, useNavigate } from "react-router-dom";
import Carrossel from "../../Components/Carousel";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Alert,
  Box,
  Button,
  Grid,
  IconButton,
  TextField,
  Typography,
  MenuItem,
  Slider,
} from "@mui/material";
import { LoadingButton } from "@mui/lab";
import { Vehicle, Despesa, Prestador, Venda } from "../../types";
import TableDespesas from "../../Components/TableDespesas";
import {
  addDespesaSold,
  deleteDespesaSold,
  getSoldVehicleByPlate,
  getVehicleByPlate,
  listenToUpdates,
  listenToUpdatesVendas,
  updateVehicle,
} from "../../Services/FirebaseDatabase";
import SendIcon from "@mui/icons-material/Send";
import InfoIcon from "@mui/icons-material/Info";
import LocalAtmIcon from "@mui/icons-material/LocalAtm";
import CalculateIcon from "@mui/icons-material/Calculate";
import {
  fetchPrestadores,
  fetchVehicles,
  fetchVendas,
  fetchVendedores,
} from "../../Store/Veículos";
import { useDispatch } from "react-redux";
import { toNumber, toReais } from "../../Services/AuxiliarFunctions";
import CameraAltIcon from "@mui/icons-material/CameraAlt";
import Picture from "../../Components/Picture";
import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
import { Carousel } from "react-responsive-carousel";

export default function SoldCar() {
  const dispatch = useDispatch<AppDispatch>();
  const navigate = useNavigate();

  const prestadoresSlice = useSelector((state: RootState) => state.prestadores);
  const vehicles = useSelector((state: RootState) => state.vehicles);
  const { placa } = useParams();
  const [car, setCar] = useState<Vehicle>();
  const [index, setIndex] = useState<number>();
  const [despesas, setDespesas] = useState<any>();
  const [form, setForm] = useState<Vehicle>({ ...(car as Vehicle) });
  const [formVenda, setFormVenda] = useState({} as Venda);
  const [attLoading, setAttLoading] = useState<boolean>(false);
  const [despesasLoading, setDespesasLoading] = useState<boolean>(false);
  const [newDespesa, setNewDespesa] = useState<Despesa>({} as Despesa);
  const [success, setSuccess] = useState<boolean>(false);
  const [successDespesas, setSuccessDespesas] = useState<boolean>(false);
  const [valorCompraNumber, setValorCompraNumber] = useState<number>();
  const [somaDespesas, setSomaDespesas] = useState<number>();
  const [valorVenda, setValorVenda] = useState<any>();
  const [inputMargem, setInputMargem] = useState<number>(0);
  const [prestadores, setPrestadores] = useState<Array<Prestador>>();
  const [errorVenda, setErrorVenda] = useState("");
  const [colorValorVenda, setColorValorVenda] = useState<
    "brown" | "rgb(242, 165, 2)" | "green"
  >();
  const [errorAtt, setErrorAtt] = useState<string | null>(null);

  const onInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    let value = e.target.value;
    const id = e.target.id;
    setForm({
      ...form,
      [id]: value,
    });
  };

  const onDespesaChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    let id = e.target.id;
    if (!id) {
      id = "prestador";
    }

    setNewDespesa({
      ...newDespesa,
      [id]: e.target.value,
    });
  };

  async function onClickAtualizar() {
    setErrorAtt(null);
    setSuccess(false);
    try {
      if (!form.cor || !form.valorCompra || !form.placa || !form.renavam) {
        setErrorAtt("informe os campos necessários.");
        throw new Error();
      }

      let newValorVenda = form.valorVenda;
      let newValorCompra = form.valorCompra;

      if (
        !isNaN(parseFloat(form.valorVenda!)) &&
        isFinite(parseFloat(form.valorVenda!))
      ) {
        newValorVenda = Number(form.valorVenda!).toLocaleString("pt-BR", {
          style: "currency",
          currency: "BRL",
        });
      }
      setSuccess(false);
      setAttLoading(true);
      await updateVehicle({
        ...form,
        valorCompra: newValorCompra,
        valorVenda: newValorVenda,
        despesas: despesas ? despesas : [],
        fotos: car!.fotos,
        opcionais: getSelectedCheckboxes(),
      });
      listenToUpdates().then((vehicles) => {
        if (vehicles[index!]) {
          setCar(vehicles[index!]);
          setForm(vehicles[index!]);
          setDespesas(vehicles[index!].despesas);
          setValorCompraNumber(
            Number(
              vehicles[index!].valorCompra
                .replace("R$", "")
                .split(",")[0]
                .replace(".", "")
            )
          );
        } else setDespesas([]);
        const attValorVenda = toNumber(form.valorVenda as string);
        setValorVenda(toNumber(form.valorVenda as string));
        setInputMargem(
          Number(
            Number(
              (attValorVenda / (somaDespesas! + valorCompraNumber!) - 1) * 100
            ).toFixed(2)
          )
        );
      });
      setAttLoading(false);
      setSuccess(true);
    } catch {
      (err: any) => {
        console.log(err);
      };
    }
  }

  async function onClickAddDespesas() {
    setSuccessDespesas(false);
    setDespesasLoading(true);
    await addDespesaSold(newDespesa, car!.placa);
    setNewDespesa({ ...newDespesa, descrição: "", valor: "", data: "" });
    setDespesasLoading(false);
    setSuccessDespesas(true);
    somarDespesas();
  }

  async function onClickDelete(id: string | undefined) {
    if (!id || !car) {
      return;
    }
    await deleteDespesaSold(id, car?.placa!);
    listenToUpdatesVendas(placa!)
      .then((venda: Venda[]) => {
        setDespesas(venda);
      })
      .catch((error: any) => {
        console.error(error);
      });
    console.log(calcLucro());
  }

  function toMiniaturaClick(url: string) {
    const newFotos = car!.fotos.filter((foto) => foto != url);
    newFotos.unshift(url);
    setCar({
      ...car!,
      fotos: newFotos,
    });
    setForm({
      ...form,
      fotos: newFotos,
    });
  }

  const onChangeinputMargem = (e: Event, value: number | number[]) => {
    if (value === null) return;
    setInputMargem(Number(value));
    const newValorvenda =
      (valorCompraNumber! + somaDespesas!) * (1 + Number(value) / 100);
    setForm({
      ...form,
      valorVenda: toReais(newValorvenda),
    });
    setValorVenda(newValorvenda);
  };

  function somarDespesas() {
    let soma = 0;
    for (let key in despesas) {
      soma += Number(despesas[key].valor);
    }
    setSomaDespesas(soma);
    setValorVenda(
      (valorCompraNumber! + soma) * (1 + Number(inputMargem) / 100)
    );
    return soma;
  }

  function onClickRemoveImage(url: string) {
    const newFotos = car!.fotos.filter((foto) => foto != url);
    setCar({
      ...car!,
      fotos: newFotos,
    });
    setForm({
      ...form,
      fotos: newFotos,
    });
  }

  function getSelectedCheckboxes(): string[] {
    const checkboxes = document.querySelectorAll<HTMLInputElement>(
      'input[type="checkbox"]'
    );
    const selectedCheckboxes: string[] = [];

    checkboxes.forEach((checkbox) => {
      if (checkbox.checked) {
        selectedCheckboxes.push(checkbox.labels![0].textContent ?? "");
      }
    });
    console.log(selectedCheckboxes);

    return selectedCheckboxes.filter((checkbox) => checkbox != "Sem troca");
  }

  function calcLucro() {
    const lucro =
      Number(Number() + Number(formVenda.ValorCompraTroca)) -
      (somaDespesas! + valorCompraNumber!);
    return lucro;
  }

  useEffect(() => {
    listenToUpdatesVendas(placa!)
      .then((venda: Venda[]) => {
        setDespesas(venda);
      })
      .catch((error: any) => {
        console.error(error);
      });
  }, [success, despesasLoading]);

  useEffect(() => {
    async function pegarVeiculopelaPlaca() {
      const find = await getSoldVehicleByPlate(placa!);
      if (!find) throw new Error("Veículo com essa placa não encontrado");
      setCar(find);
      setIndex(vehicles.data.findIndex((i) => i.placa == placa));
      setDespesas(find.despesas);
      setForm({
        ...find,
      });
      const valorCompraN = Number(
        find.valorCompra.replace("R$", "").split(",")[0].replace(".", "")
      );
      const valorVendaN = Number(
        find.valorVenda?.replace("R$", "").split(",")[0].replace(".", "")
      );
      setValorCompraNumber(valorCompraN);
      let soma = 0;
      for (let key in find.despesas) {
        soma += Number(find.despesas[key].valor);
      }
      setSomaDespesas(soma);
      setValorVenda(valorVendaN);
      const custo = soma + valorCompraN;
      setInputMargem(
        Number(Number((valorVendaN / custo - 1) * 100).toFixed(2))
      );
      setPrestadores(prestadoresSlice.data);
    }
    pegarVeiculopelaPlaca();
  }, [dispatch]);

  useEffect(() => {
    somarDespesas();
  }, [inputMargem, despesas]);

  useEffect(() => {
    const custo = somarDespesas() + valorCompraNumber!;
    if (valorVenda < custo * 1.05) setColorValorVenda("brown");
    if (valorVenda >= custo * 1.05 && valorVenda < custo * 1.1)
      setColorValorVenda("rgb(242, 165, 2)");
    if (valorVenda >= custo * 1.1) setColorValorVenda("green");
  }, [valorVenda]);

  useEffect(() => {
    if (!localStorage.getItem("user")) navigate("/");
    dispatch(fetchVehicles());
    dispatch(fetchVendas());
    dispatch(fetchPrestadores());
    dispatch(fetchVendedores());
  }, [dispatch]);

  if (!car) return <></>;

  return (
    <>
      <Content>
        <Grid container flexDirection={"row-reverse"}>
          <Grid container xs={12} lg={7}>
            <Grid xs={12} padding={2} item>
              <Box marginBottom={3} textAlign={"center"}>
                <Typography variant={"h3"}>{car?.fipeInfo.model}</Typography>
              </Box>

              <Accordion
                sx={{
                  marginBottom: "2%",
                }}
              >
                <AccordionSummary>
                  <Button
                    sx={{ width: "100%" }}
                    size="large"
                    color="primary"
                    component="label"
                  >
                    <InfoIcon fontSize="large" />
                  </Button>
                </AccordionSummary>

                <AccordionDetails>
                  <Box
                    component="form"
                    noValidate
                    autoComplete="off"
                    textAlign={"center"}
                    sx={{
                      "& .MuiTextField-root": { m: 1, my: 2 },
                    }}
                  >
                    <TextField
                      sx={{
                        width: "25%",
                      }}
                      variant="outlined"
                      required
                      value={car?.fipeInfo.brand}
                      disabled
                      id="marca"
                      label="Marca"
                    ></TextField>

                    <TextField
                      sx={{
                        width: "65%",
                      }}
                      variant="outlined"
                      value={car?.fipeInfo.model}
                      disabled
                      id="modelo"
                      label="Modelo"
                    ></TextField>

                    <TextField
                      sx={{
                        width: "29%",
                      }}
                      required
                      disabled
                      value={car?.fipeInfo.modelYear}
                      id="ano"
                      label="Ano"
                      variant="outlined"
                    ></TextField>

                    <TextField
                      sx={{
                        width: "29.25%",
                      }}
                      variant="outlined"
                      disabled
                      label="Placa"
                      onChange={onInputChange}
                      value={form?.placa}
                      id="placa"
                    />
                    <TextField
                      sx={{
                        width: "29.25%",
                      }}
                      value={form?.cor}
                      disabled
                      onChange={onInputChange}
                      variant="outlined"
                      id="cor"
                      label="Cor"
                    />
                    <TextField
                      sx={{
                        width: "45%",
                      }}
                      id="renavam"
                      disabled
                      onChange={onInputChange}
                      value={form?.renavam}
                      variant="outlined"
                      label="Renavam"
                    />
                    <TextField
                      disabled
                      sx={{ width: "45%" }}
                      value={form?.valorCompra}
                      onChange={onInputChange}
                      variant="outlined"
                      type="text"
                      id="valorCompra"
                      label="Valor da Compra"
                    />
                    <TextField
                      sx={{
                        width: "35%",
                      }}
                      disabled
                      variant="outlined"
                      label="Data Compra"
                      value={form?.dataCompra}
                      id="dataCompra"
                    />
                    <TextField
                      id="valorVenda"
                      disabled
                      variant="outlined"
                      value={form?.valorVenda}
                      label="Preço de Venda"
                      sx={{
                        width: "55%",
                        color: "black",
                      }}
                      onChange={onInputChange}
                    />

                    {success ? (
                      <Alert
                        sx={{
                          width: "300px",
                          marginLeft: "27%",
                        }}
                        severity="success"
                      >
                        Atualizado com Sucesso.
                      </Alert>
                    ) : (
                      <></>
                    )}

                    {errorAtt ? (
                      <Alert
                        sx={{
                          width: "300px",
                          marginLeft: "27%",
                        }}
                        severity="error"
                      >
                        {errorAtt}
                      </Alert>
                    ) : (
                      <></>
                    )}
                  </Box>
                </AccordionDetails>
              </Accordion>

              <Accordion
                sx={{
                  marginBottom: "2%",
                }}
              >
                <AccordionSummary>
                  {/* {JSON.stringify(form)} */}
                  <Button
                    sx={{ width: "100%" }}
                    size="large"
                    color="primary"
                    component="label"
                  >
                    <CameraAltIcon fontSize="large" />
                  </Button>
                </AccordionSummary>

                <AccordionDetails sx={{ textAlign: "center" }}>
                  <Box
                    sx={{
                      display: "grid",
                      gridTemplateColumns: "1fr 1fr 1fr 1fr",
                      gridGap: "20px",
                      "@media (max-width: 768px)": {
                        gridTemplateColumns: "1fr 1fr",
                        gridGap: "30px",
                      },
                    }}
                  >
                    {car?.fotos ? (
                      car!.fotos.map((foto, i) =>
                        i === 0 ? (
                          <Picture
                            toMiniaturaClick={toMiniaturaClick}
                            removeClick={onClickRemoveImage}
                            type="miniatura"
                            src={foto}
                          />
                        ) : (
                          <Picture
                            toMiniaturaClick={toMiniaturaClick}
                            removeClick={onClickRemoveImage}
                            type="outras"
                            src={foto}
                          />
                        )
                      )
                    ) : (
                      <></>
                    )}
                  </Box>
                  <Box display={"flex"} justifyContent={"center"}></Box>

                  {success ? (
                    <Alert
                      sx={{
                        width: "300px",
                        marginLeft: "27%",
                      }}
                      severity="success"
                    >
                      Atualizado com Sucesso.
                    </Alert>
                  ) : (
                    <></>
                  )}

                  {errorAtt ? (
                    <Alert
                      sx={{
                        width: "300px",
                        marginLeft: "27%",
                      }}
                      severity="error"
                    >
                      {errorAtt}
                    </Alert>
                  ) : (
                    <></>
                  )}
                </AccordionDetails>
              </Accordion>

              <Accordion
                sx={{
                  marginBottom: "2%",
                }}
              >
                <AccordionSummary>
                  <Button
                    sx={{ width: "100%" }}
                    size="large"
                    color="primary"
                    component="label"
                  >
                    <LocalAtmIcon fontSize="large" />
                  </Button>
                </AccordionSummary>

                <AccordionDetails
                  sx={{
                    textAlign: "center",
                  }}
                >
                  <Typography variant="h5" gutterBottom>
                    Cadastrar Despesa Adicional
                  </Typography>

                  <TextField
                    value={newDespesa.descrição}
                    sx={{ marginLeft: "1%", width: "50%" }}
                    onChange={onDespesaChange}
                    id="descrição"
                    label="Descrição"
                    variant="standard"
                  />

                  <TextField
                    onChange={onDespesaChange}
                    id="valor"
                    value={newDespesa.valor}
                    label="Valor"
                    variant="standard"
                    sx={{ marginLeft: "4%", width: "40%" }}
                  />

                  <TextField
                    id="data"
                    value={newDespesa.data}
                    onChange={onDespesaChange}
                    type={"date"}
                    label="."
                    variant="standard"
                    sx={{ marginLeft: "1%", width: "50%" }}
                  />
                  <TextField
                    select
                    id="prestador"
                    value={newDespesa.prestador}
                    onChange={onDespesaChange}
                    label="Prestador"
                    variant="standard"
                    sx={{ marginLeft: "4%", width: "40%" }}
                  >
                    {prestadores ? (
                      prestadores.map((p) => (
                        <MenuItem key={p.id} value={p.nome}>
                          {p.nome}
                        </MenuItem>
                      ))
                    ) : (
                      <></>
                    )}
                  </TextField>

                  <LoadingButton
                    sx={{
                      marginTop: "3%",
                    }}
                    onClick={onClickAddDespesas}
                    loading={despesasLoading}
                    color="primary"
                    aria-label="upload picture"
                    component="label"
                  >
                    <SendIcon fontSize="medium" />
                  </LoadingButton>
                  {successDespesas ? (
                    <Alert
                      sx={{
                        width: "100%",
                      }}
                      severity="success"
                    >
                      Cadastrado com Sucesso!.
                    </Alert>
                  ) : (
                    <></>
                  )}
                </AccordionDetails>
              </Accordion>

              <Accordion
                sx={{
                  marginBottom: "2%",
                }}
              >
                <AccordionSummary>
                  <Button
                    sx={{ width: "100%" }}
                    size="large"
                    color="primary"
                    component="label"
                  >
                    <CalculateIcon fontSize="large" />
                  </Button>
                </AccordionSummary>
                <AccordionDetails>
                  <Grid container>
                    <Grid item xs={12} md={7}>
                      <Typography m={1} variant="h5">
                        Valor Compra: <strong> {car?.valorCompra} </strong>
                      </Typography>
                      <Typography m={1} variant="h5">
                        Total Despesas:
                        <strong style={{ color: "red" }}>
                          {somaDespesas?.toLocaleString("pt-BR", {
                            style: "currency",
                            currency: "BRL",
                          })}
                        </strong>
                      </Typography>
                      <Typography m={1} variant="h5">
                        Valor Fipe:
                        <strong style={{ color: "midnightblue" }}>
                          {car?.fipeInfo.price}
                        </strong>
                      </Typography>
                    </Grid>
                    <Grid item xs={12} md={5}>
                      <Typography m={1} variant="h5">
                        Margem de Lucro: {`${inputMargem ? inputMargem : ""}%`}
                        <Box width={300}>
                          <Slider
                            defaultValue={50}
                            aria-label="Default"
                            valueLabelDisplay="auto"
                            onChange={onChangeinputMargem}
                          />
                        </Box>
                      </Typography>
                    </Grid>
                    <Grid item xs={12} md={7}>
                      <Typography m={1} variant="h5">
                        Valor de Venda:{" "}
                        <strong style={{ color: colorValorVenda }}>
                          {" "}
                          {typeof toReais(valorVenda) == "number"
                            ? toReais(valorVenda)
                            : form.valorVenda}{" "}
                        </strong>
                        <IconButton
                          color="primary"
                          onClick={onClickAtualizar}
                        ></IconButton>
                      </Typography>
                    </Grid>
                  </Grid>
                </AccordionDetails>
              </Accordion>
            </Grid>
          </Grid>

          <Grid container xs={12} lg={5}>
            <Grid
              xs={12}
              item
              sx={{
                overflow: "hidden",
              }}
            >
              <Carousel>
                {car?.fotos.map((foto) => (
                  <div>
                    <img src={foto} alt="" />
                  </div>
                ))}
              </Carousel>

              <TableDespesas
                onClickDelete={onClickDelete}
                placa={car?.placa}
                data={despesas}
              />
            </Grid>
          </Grid>
        </Grid>
      </Content>
      <Appbar />
    </>
  );
}

import { Accordion, AccordionDetails, AccordionSummary, Box, TextField, Typography, MenuItem, IconButton } from '@mui/material'
import React, { useState, useEffect } from 'react'
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import SaveIcon from '@mui/icons-material/Save';
import { Prestador } from '../types';
import { addPrestador, deletePrestador, listenToUpdates } from '../Services/FirebaseDatabase';
import { AppDispatch} from '../Store';
import { useDispatch } from 'react-redux';
import { fetchPrestadores } from '../Store/Veículos';
import DeleteIcon from '@mui/icons-material/Delete';


interface CardPrestador {
    type: 'settings' | 'cadastro'
    data: {nome: string, tipo: string} | undefined
}


export default function CadastroPrestadores(props: CardPrestador) {

    useEffect(() => {
        if(props.data){
            setForm({ nome: props.data.nome, tipo: props.data.tipo, })
        }
        else  setForm({ nome: '', tipo: ''})
    }, [props.data])

    const [form, setForm] = useState<Prestador>({ nome: '', tipo: '', })

    const dispatch = useDispatch<AppDispatch>();

   

    const onChangeForm = (e: React.ChangeEvent<HTMLInputElement>) => {
        if (e.target.id == undefined) {
            setForm({
                ...form,
                tipo: e.target.value
            })
            return
        }
        const id = e.target.id
        setForm({
            ...form,
            [id]: e.target.value
        })
    }

    async function onClickCadastrarPrestador() {
        await addPrestador(form)
        setForm({ nome: '', tipo: '', })
        listenToUpdates().then(vehicles => {
            dispatch(fetchPrestadores())
        })
    }

    async function onClickEditPrestador() {
        await deletePrestador(props.data!.nome)
        await addPrestador(form)
        setForm({ nome: '', tipo: '', })
        listenToUpdates().then(vehicles => {
            dispatch(fetchPrestadores())
        })
    }

    async function onClickDeletarPrestador() {
        await deletePrestador(props.data!.nome)
        setForm({ nome: '', tipo: '', })
        listenToUpdates().then(vehicles => {
            dispatch(fetchPrestadores())
        })
    }


    return (
        <>
            {props.type === 'cadastro' ? 
            <Accordion>
            <AccordionSummary
                aria-controls="panel1a-content"
                id="panel1a-header"
                expandIcon={<ExpandMoreIcon />}
            >    <Typography>Cadastro Prestador</Typography>

            </AccordionSummary>
            <AccordionDetails >
                <Box
                    component="form"
                    noValidate
                    autoComplete="off"
                    sx={{
                        '& .MuiTextField-root': { m: 1, my: 2, width: '30%' },
                    }}>
                    <TextField
                        id="nome"
                        label="Nome"
                        value={form.nome}
                        onChange={onChangeForm}
                    />
                    <TextField
                        select
                        id="tipo"
                        label="Tipo"
                        value={form.tipo}
                        onChange={onChangeForm}>
                        <MenuItem value={'Oficina'} >
                            {'Oficina'}
                        </MenuItem>
                        <MenuItem value={'Polimento'}>
                            {'Polimento'}
                        </MenuItem>
                        <MenuItem value={'Chapeamento'}>
                            {'Chapeamento'}
                        </MenuItem>
                        <MenuItem value={'Peças'}>
                            {'Peças'}
                        </MenuItem>
                        <MenuItem value={'Lavagem'}>
                            {'Lavagem'}
                        </MenuItem>
                        <MenuItem value={'Pintura'}>
                            {'Pintura'}
                        </MenuItem>
                        <MenuItem value={'Outros'}>
                            {'Outros'}
                        </MenuItem>
                    </TextField>
                    <IconButton sx={{
                        marginTop: '2%'
                    }}
                        onClick={onClickCadastrarPrestador}>
                        <SaveIcon color='primary' fontSize='medium' />
                    </IconButton>
                </Box>
            </AccordionDetails>
        </Accordion>
         :
         <Accordion>
            <AccordionSummary
                aria-controls="panel1a-content"
                id="panel1a-header"
                expandIcon={<ExpandMoreIcon />}
            >    <Typography>Configurações Prestador</Typography>

            </AccordionSummary>
            <AccordionDetails >
                <Box
                    component="form"
                    noValidate
                    autoComplete="off"
                    sx={{
                        '& .MuiTextField-root': { m: 1, my: 2, width: '30%' },
                    }}>
                    <TextField
                        id="nome"
                        label="Nome"
                        value={form.nome}
                        onChange={onChangeForm}
                    />
                    <TextField
                        select
                        id="tipo"
                        label="Tipo"
                        value={form.tipo}
                        onChange={onChangeForm}>
                        <MenuItem value={'Oficina'} >
                            {'Oficina'}
                        </MenuItem>
                        <MenuItem value={'Polimento'}>
                            {'Polimento'}
                        </MenuItem>
                        <MenuItem value={'Chapeamento'}>
                            {'Chapeamento'}
                        </MenuItem>
                        <MenuItem value={'Peças'}>
                            {'Peças'}
                        </MenuItem>
                        <MenuItem value={'Lavagem'}>
                            {'Lavagem'}
                        </MenuItem>
                        <MenuItem value={'Pintura'}>
                            {'Pintura'}
                        </MenuItem>
                        <MenuItem value={'Outros'}>
                            {'Outros'}
                        </MenuItem>
                    </TextField>
                    <IconButton sx={{
                        marginTop: '2%'
                    }}
                        onClick={onClickEditPrestador}>
                        <SaveIcon color='primary' fontSize='medium' />
                    </IconButton>

                    <IconButton sx={{
                        marginTop: '2%'
                    }}
                        onClick={onClickDeletarPrestador}>
                        <DeleteIcon color='primary' fontSize='medium' />
                    </IconButton>
                </Box>
            </AccordionDetails>
        </Accordion>

        }

            
        </>
    )

}
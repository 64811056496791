import React from "react";
import { Box } from "@mui/material";
import IconButton from "@mui/material/IconButton";
import RemoveCircleSharpIcon from "@mui/icons-material/RemoveCircleSharp";
import SwapVerticalCircleIcon from "@mui/icons-material/SwapVerticalCircle";

interface PictureProps {
  src: string;
  type: string;
  removeClick: (url: string) => void;
  toMiniaturaClick: (url: string) => void;
}

export default function Picture(props: PictureProps) {
  return (
    <Box
      position={"relative"}
      sx={{
        width: "100%",
        height: "100%",
      }}
    >
      <IconButton
        onClick={() => props.removeClick(props.src)}
        sx={{
          backgroundColor: "white",
          padding: "0",
          position: "absolute",
          top: "-5%",
          right: "-10%",
          "&:hover": {
            backgroundColor: "white",
          },
        }}
      >
        <RemoveCircleSharpIcon fontSize="medium" color="primary" />
      </IconButton>

      <IconButton
        onClick={() => props.toMiniaturaClick(props.src)}
        sx={{
          backgroundColor: "white",
          padding: "0",
          position: "absolute",
          top: "20%",
          right: "-10%",
          "&:hover": {
            backgroundColor: "white",
          },
        }}
      >
        <SwapVerticalCircleIcon fontSize="medium" color="primary" />
      </IconButton>

      <img
        style={{
          width: props.type === "miniatura" ? "100%" : "100%",
          objectFit: "cover",
        }}
        src={props.src}
        alt=""
      />
    </Box>
  );
}

import * as React from "react";
import dayjs, { Dayjs } from "dayjs";
import "dayjs/locale/pt-br";
import TextField from "@mui/material/TextField";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";

dayjs.locale("pt-br");

interface FilterButton {
  filterDate: (value: string | null) => void;
}

export default function FilterButton(props: FilterButton) {
  const [value, setValue] = React.useState<Dayjs | null>(dayjs(Date.now()));
  const formattedValue = value ? value.format("MMMM YYYY") : "";

  React.useEffect(() => {
    props.filterDate(formattedValue);
  }, [value]);

  return (
    <LocalizationProvider dateAdapter={AdapterDayjs} locale="pt-br">
      <DatePicker
        views={["year", "month"]}
        minDate={dayjs("2022-01-01")}
        maxDate={dayjs("2023-12-01")}
        inputFormat={value ? "MMMM/YYYY" : ""}
        value={value}
        onChange={(newValue) => {
          setValue(newValue);
        }}
        renderInput={(params) => (
          <TextField sx={{ backgroundColor: "white" }} {...params} />
        )}
      />
    </LocalizationProvider>
  );
}

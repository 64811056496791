import { useState, useEffect } from "react";
import { styled } from "@mui/material/styles";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell, { tableCellClasses } from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper, { paperClasses } from "@mui/material/Paper";
import { Despesa } from "../types";
import DeleteIcon from "@mui/icons-material/Delete";
import { IconButton } from "@mui/material";
import { deleteDespesa } from "../Services/FirebaseDatabase";
import { formatDate } from "../Services/AuxiliarFunctions";
import { useSelector } from "react-redux";
import { RootState } from "../Store";

interface TableDespesas {
  data?: Array<Despesa>;
  placa: string | undefined;
  onClickDelete: (id: string | undefined) => void;
}

export default function TableDespesas(props: TableDespesas) {
  const UserConfig = useSelector((state: RootState) => state.UserConfig);

  const StyledTableCell = styled(TableCell)(({ theme }) => ({
    [`&.${tableCellClasses.head}`]: {
      backgroundColor: UserConfig.color,
      color: theme.palette.common.white,
    },
    [`&.${tableCellClasses.body}`]: {
      fontSize: 14,
    },
  }));

  const StyledTableRow = styled(TableRow)(({ theme }) => ({
    "&:nth-of-type(odd)": {
      backgroundColor: theme.palette.action.hover,
    },
    // hide last border
    "&:last-child td, &:last-child th": {
      border: 0,
    },
  }));

  useEffect(() => {
    if (props.data) {
      setDespesas(Object.values(props.data));
    }
  }, [props.data]);

  const [despesas, setDespesas] = useState<Array<Despesa>>([]);

  return (
    <TableContainer
      sx={{
        marginBottom: "10%",
      }}
      component={Paper}
    >
      <Table aria-label="customized table">
        <TableHead>
          <TableRow>
            <StyledTableCell>Descrição</StyledTableCell>
            <StyledTableCell align="right">Valor</StyledTableCell>
            <StyledTableCell align="right">Prestador</StyledTableCell>
            <StyledTableCell align="right">Data</StyledTableCell>
            <StyledTableCell align="right"></StyledTableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {despesas.length > 0 ? (
            despesas.map((row) => (
              <StyledTableRow key={row!.id}>
                <StyledTableCell component="th" scope="row">
                  {row!.descrição}
                </StyledTableCell>
                <StyledTableCell align="right">
                  {Number(row.valor).toLocaleString("pt-BR", {
                    style: "currency",
                    currency: "BRL",
                  })}
                </StyledTableCell>
                <StyledTableCell align="right">{row.prestador}</StyledTableCell>
                <StyledTableCell align="right">
                  {row.data && formatDate(row.data.toString())}
                </StyledTableCell>
                <StyledTableCell align="right">
                  <IconButton>
                    <DeleteIcon onClick={() => props.onClickDelete(row.id)} />
                  </IconButton>
                </StyledTableCell>
              </StyledTableRow>
            ))
          ) : (
            <></>
          )}
        </TableBody>
      </Table>
    </TableContainer>
  );
}

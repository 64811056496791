import React, { useEffect, useState } from "react";
import SettingsIcon from "@mui/icons-material/Settings";
import IconButton from "@mui/material/IconButton";
import { getVendas, listenToUpdates } from "../../Services/FirebaseDatabase";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import Typography from "@mui/material/Typography";
import { Despesa, Prestador, Vehicle } from "../../types";
import { toReais } from "../../Services/AuxiliarFunctions";
import { useDispatch } from "react-redux";
import { AppDispatch, RootState } from "../../Store";
import { useSelector } from "react-redux";
interface CardPrestador {
  data: Prestador;
  changeCadastro: (nome: string, tipo: string) => void;
}

export default function CardPrestador(props: CardPrestador) {
  const [totalGasto, setTotalGasto] = useState<string>("0");

  const vendasSlice = useSelector((state: RootState) => state.vendas.data);
  const vehiclesSlice = useSelector((state: RootState) => state.vehicles.data);

  useEffect(() => {
    let soma = 0;

    vehiclesSlice.forEach((v: Vehicle) => {
      if (v.despesas) {
        const array = Object.values(v.despesas).filter(
          (obj) => obj.prestador === props.data.nome
        );
        array.forEach((d: Despesa) => {
          soma += Number(d.valor);
        });
      }

      if (!vendasSlice) {
        console.error("vendas is not defined");
        return;
      }

      vendasSlice.forEach((venda: unknown) => {
        if (!venda) {
          return;
        }
        if (typeof venda === "object" && "vehicle" in venda) {
          const v = venda as { vehicle: Vehicle };
          if (!v.vehicle.despesas) return;
          Object.values(v.vehicle.despesas).forEach((desp) => {
            if (desp.prestador === props.data.nome) {
              soma += Number(desp.valor);
            }
          });
        }
      });
      setTotalGasto(toReais(soma));
    });
  }, []);

  return (
    <Card
      sx={{
        position: "relative",
        margin: "0.5%",
        height: 120,
        width: 275,
        textAlign: "center",
      }}
    >
      <CardContent>
        <Typography variant="h4">
          <strong> {props.data.nome}</strong>
        </Typography>
        <br />
        <IconButton
          onClick={() => props.changeCadastro(props.data.nome, props.data.tipo)}
          sx={{
            position: "absolute",
            bottom: "1%",
            right: "1%",
          }}
        >
          <SettingsIcon />
        </IconButton>
      </CardContent>
    </Card>
  );
}

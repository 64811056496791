import Paper from "@mui/material/Paper";
import InputBase from "@mui/material/InputBase";
import IconButton from "@mui/material/IconButton";
import SearchIcon from "@mui/icons-material/Search";
import { Grid } from "@mui/material";
import TableRowsIcon from "@mui/icons-material/TableRows";
import WindowIcon from "@mui/icons-material/Window";
import Box from "@mui/material/Box";
import SortButton from "./Buttons/SortButton";
import FilterButton from "./Buttons/FilterButton";

interface ControllerBar {
  onChangeSearch: any;
  component?: "prestadores" | "vendedores" | undefined;
  onChangeFormat?: (state: string) => void;
  filterDate?: (value: string | null) => void;
  sortChange?: (value: string | null) => void;
}

function ControllerBar(props: ControllerBar) {
  return (
    <>
      <Grid
        position={"relative"}
        alignItems={"center"}
        container
        textAlign={"center"}
        justifyContent="center"
      >
        <Grid item mx={6} xs={12} md={3}>
          {props.component === "prestadores" ||
          props.component === "vendedores" ? (
            <FilterButton filterDate={props.filterDate!} />
          ) : (
            <></>
          )}
        </Grid>

        <Grid p={2} item mx={6} xs={12} md={3}>
          <Paper component="form">
            <IconButton type="button" sx={{ p: "10px" }} aria-label="search">
              <SearchIcon />
            </IconButton>
            <InputBase
              sx={{ ml: 1, flex: 1 }}
              placeholder="Pesquisar"
              onChange={(e) => {
                props.onChangeSearch(e.target.value);
              }}
            />
          </Paper>
        </Grid>

        <Grid item mx={6} xs={12} md={3}>
          {props.component === "prestadores" ? (
            <Box>
              <IconButton onClick={() => props.onChangeFormat!("table")}>
                <TableRowsIcon />
              </IconButton>

              <IconButton onClick={() => props.onChangeFormat!("box")}>
                <WindowIcon />
              </IconButton>
              <IconButton>
                <SortButton
                  sortChange={props.sortChange ? props.sortChange : null}
                />
              </IconButton>
            </Box>
          ) : (
            <></>
          )}
        </Grid>
      </Grid>
    </>
  );
}

export default ControllerBar;

import Typography from "@mui/material/Typography";
import { Box, Icon } from "@mui/material";
import SellIcon from "@mui/icons-material/Sell";
import Avatar from "@mui/material/Avatar";
import { useEffect, useState } from "react";
import ArrowDropUpIcon from "@mui/icons-material/ArrowDropUp";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";

interface TotalNumeroVendasProps {
  infoVendas: any;
}

export default function TotalNumeroVendas(props: TotalNumeroVendasProps) {
  const [relaçãoNumeroVendas, setRelaçãoNumeroVendas] = useState<number>();
  const [vendasNumber, setVendasNumber] = useState(0);
  const [tempoAnimacao] = useState(5000);

  useEffect(() => {
    const mesPassado =
      props.infoVendas.numeroVendasLastMonth != 0
        ? props.infoVendas.numeroVendasLastMonth
        : 1;
    const rel = Number(
      ((props.infoVendas.numeroVendas / mesPassado - 1) * 100).toFixed(0)
    );
    setRelaçãoNumeroVendas(rel);
  }, [props]);

  useEffect(() => {
    const incremento = Math.ceil(
      props.infoVendas.numeroVendas / (tempoAnimacao / 10)
    );
    const intervalo = setInterval(() => {
      setVendasNumber((numeroAntigo) => {
        const diferenca = props.infoVendas.numeroVendas - numeroAntigo;
        const porcentagem = diferenca / props.infoVendas.numeroVendas;
        let novoIncremento = incremento;
        if (porcentagem <= 0.2) {
          novoIncremento = Math.ceil(incremento / 2);
        }
        if (numeroAntigo < props.infoVendas.numeroVendas) {
          return numeroAntigo + novoIncremento;
        }
        clearInterval(intervalo);
        return props.infoVendas.numeroVendas;
      });
    }, 30);
    return () => clearInterval(intervalo);
  }, [props]);

  return (
    <>
      <Box alignItems={"center"} display={"flex"} padding={1}>
        <Avatar
          sx={{ width: 70, height: 70, backgroundColor: "rgb(246, 191, 12)" }}
        >
          <SellIcon fontSize="large" />
        </Avatar>

        <Box>
          <Typography fontFamily={"sans-serif"} color={"grey"} mx={2}>
            Vendas no Mês
          </Typography>
          <Typography
            fontFamily={"monospace"}
            variant="h2"
            color={"black"}
            mx={2}
          >
            <strong>{vendasNumber}</strong>
            {(relaçãoNumeroVendas && relaçãoNumeroVendas > 0) ||
            relaçãoNumeroVendas === 0 ? (
              <span
                style={{
                  marginBottom: "5px",
                  color: "green",
                  fontFamily: "sans-serif",
                  fontSize: "30%",
                }}
              >
                &nbsp;
                <Icon>
                  <ArrowDropUpIcon
                    fontSize="large"
                    style={{ color: "green" }}
                  />
                </Icon>
                &nbsp;{relaçãoNumeroVendas}%
                <br />
              </span>
            ) : (
              <span
                style={{
                  marginBottom: "5px",
                  color: "red",
                  fontFamily: "sans-serif",
                  fontSize: "30%",
                }}
              >
                &nbsp;
                <Icon>
                  <ArrowDropDownIcon
                    fontSize="large"
                    style={{ color: "red" }}
                  />
                </Icon>
                &nbsp;{-relaçãoNumeroVendas!}%
                <br />
              </span>
            )}
          </Typography>
        </Box>
      </Box>
    </>
  );
}

import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  TextField,
  Typography,
  MenuItem,
  IconButton,
  Grid,
} from "@mui/material";
import React, { useState, useEffect } from "react";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import SaveIcon from "@mui/icons-material/Save";
import { Prestador, Vendedor } from "../types";
import {
  addPrestador,
  addVendedor,
  deletePrestador,
  listenToUpdates,
  listenToUpdatesVendedores,
} from "../Services/FirebaseDatabase";
import { AppDispatch } from "../Store";
import { useDispatch } from "react-redux";
import { fetchPrestadores, fetchVendedores } from "../Store/Veículos";
import DeleteIcon from "@mui/icons-material/Delete";
import Avatar from "@mui/material/Avatar";
import { uploadImages } from "../Services/FirebaseStorage";
import { Alert, LoadingButton } from "@mui/lab";

interface CardPrestador {
  type: "settings" | "cadastro";
  data: { nome: string; foto: string } | undefined;
  reload: () => void;
}

export default function CadastroVendedor(props: CardPrestador) {
  useEffect(() => {
    if (props.data) {
      setForm({ nome: props.data.nome, foto: props.data.foto });
    } else setForm({ nome: "", foto: "" });
  }, [props.data]);

  const [form, setForm] = useState<Vendedor>({ nome: "", foto: "" });
  const [file, setFile] = useState<File>();
  const [image, setImage] = useState<string>();
  const [loading, setLoading] = useState(false);
  const [success, setSuccess] = useState<string>();
  const [error, setError] = useState<string>();

  const dispatch = useDispatch<AppDispatch>();

  const onChangeForm = (e: React.ChangeEvent<HTMLInputElement>) => {
    const id = e.target.id;
    setForm({
      ...form,
      [id]: e.target.value,
    });
  };

  async function onClickCadastrarVendedor() {
    setSuccess("");
    if (!file || !form.nome) {
      setError("Informe os campos necessários para cadastrar o vendedor");
      return;
    }
    setLoading(true);
    setError("");
    const url: any = await uploadImages([file]);
    await addVendedor({
      nome: form.nome,
      foto: url,
    });
    setLoading(false);
    setSuccess("Cadastrado com sucesso.");
    setForm({ nome: "", foto: "" });
    listenToUpdatesVendedores().then((v) => {
      dispatch(fetchVendedores());
    });
  }

  const handleFileUpload = (e: any) => {
    const files = e.target.files;
    if (files) {
      const file = files[0];
      const url = URL.createObjectURL(file);
      setImage(url);
      setFile(file);
    }
  };

  return (
    <>
      {props.type === "cadastro" ? (
        <Accordion>
          <AccordionSummary
            aria-controls="panel1a-content"
            id="panel1a-header"
            expandIcon={<ExpandMoreIcon />}
          >
            <Typography>Cadastro Vendedor</Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Grid container>
              <Grid
                display={"flex"}
                alignItems={"center"}
                component="form"
                noValidate
                autoComplete="off"
                sx={{
                  "& .MuiTextField-root": { m: 1, my: 2 },
                }}
                item
                xs={12}
                lg={6}
              >
                <Avatar src={image} sx={{ width: 80, height: 80 }} />
                <TextField
                  type={"file"}
                  id="foto"
                  onChange={handleFileUpload}
                ></TextField>
              </Grid>
              <Grid
                display={"flex"}
                alignItems={"center"}
                justifyContent={"left"}
                component="form"
                noValidate
                autoComplete="off"
                sx={{
                  "& .MuiTextField-root": { m: 1, my: 2 },
                }}
                item
                xs={12}
                lg={6}
              >
                <TextField
                  sx={{
                    width: "50%",
                  }}
                  id="nome"
                  label="Nome"
                  value={form.nome}
                  onChange={onChangeForm}
                />

                <LoadingButton
                  variant="contained"
                  sx={{}}
                  size="large"
                  loading={loading}
                  onClick={onClickCadastrarVendedor}
                  endIcon={<SaveIcon />}
                >
                  Cadastrar
                </LoadingButton>
              </Grid>
            </Grid>

            {success ? <Alert severity="success">{success}</Alert> : <></>}
            {error ? <Alert severity="error">{error}</Alert> : <></>}
          </AccordionDetails>
        </Accordion>
      ) : (
        <Accordion>
          <AccordionSummary
            aria-controls="panel1a-content"
            id="panel1a-header"
            expandIcon={<ExpandMoreIcon />}
          >
            {" "}
            <Typography>Configurações Prestador</Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Box
              component="form"
              noValidate
              autoComplete="off"
              sx={{
                "& .MuiTextField-root": { m: 1, my: 2, width: "30%" },
              }}
            >
              <TextField
                id="nome"
                label="Nome"
                value={form.nome}
                onChange={onChangeForm}
              />

              <IconButton
                sx={{
                  marginTop: "2%",
                }}
              >
                <SaveIcon color="primary" fontSize="medium" />
              </IconButton>

              <IconButton
                sx={{
                  marginTop: "2%",
                }}
              >
                <DeleteIcon color="primary" fontSize="medium" />
              </IconButton>
            </Box>
          </AccordionDetails>
        </Accordion>
      )}
    </>
  );
}

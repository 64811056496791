import React from "react";
import Appbar from "../../Components/Appbar";
import Content from "../../Components/Content";
import ControllerBar from "../../Components/ControllerBar";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  fetchVehicles,
  fetchFipe,
  fetchVendas,
  fetchPrestadores,
  fetchVendedores,
} from "../../Store/Veículos";
import type { RootState, AppDispatch } from "../../Store/index";
import Cadastro from "../../Components/Cadastro";
import CardVehicle from "../../Components/Card/CardVehicle";
import { Box, Grid, Skeleton } from "@mui/material";
import FirtAccessModal from "../../Components/ModalFirstAccess";
import { useNavigate } from "react-router-dom";

export default function Cars() {
  const navigate = useNavigate();
  const dispatch = useDispatch<AppDispatch>();
  const vehicles = useSelector((state: RootState) => state.vehicles);

  const [vehiclesData, setVehiclesData] =
    React.useState<typeof vehicles.data>();

  function SearchChange(value: string) {
    if (value === "") {
      setVehiclesData(vehicles.data);
      return;
    }

    setVehiclesData(
      vehiclesData?.filter((vehicle) => {
        return vehicle.fipeInfo.model
          .toLowerCase()
          .includes(value.toLowerCase());
      })
    );
  }

  useEffect(() => {
    if (!localStorage.getItem("user")) navigate("/");
    dispatch(fetchFipe());
    dispatch(fetchVehicles());
    dispatch(fetchVendas());
    dispatch(fetchPrestadores());
    dispatch(fetchVendedores());
  }, [dispatch]);

  useEffect(() => {
    setVehiclesData(vehicles.data);
  }, [vehicles.data]);

  return (
    <>
      <Content>
        <Cadastro />
        <ControllerBar onChangeSearch={SearchChange} />
        <Grid px={{ xs: 2, lg: 0 }} spacing={1} container>
          {vehiclesData ? (
            vehiclesData?.map((vehicle) => (
              <Grid item xs={12} sm={6} md={4} lg={3} xl={2}>
                <CardVehicle data={vehicle} />
              </Grid>
            ))
          ) : (
            <>
              <Grid height={470} item xs={12} sm={6} md={4} lg={3} xl={2}>
                <Skeleton
                  animation="wave"
                  variant="rectangular"
                  width={"100%"}
                  height={"100%"}
                />
              </Grid>
              <Grid height={470} item xs={12} sm={6} md={4} lg={3} xl={2}>
                <Skeleton
                  animation="wave"
                  variant="rectangular"
                  width={"100%"}
                  height={"100%"}
                />
              </Grid>
              <Grid height={470} item xs={12} sm={6} md={4} lg={3} xl={2}>
                <Skeleton
                  animation="wave"
                  variant="rectangular"
                  width={"100%"}
                  height={"100%"}
                />
              </Grid>
              <Grid height={470} item xs={12} sm={6} md={4} lg={3} xl={2}>
                <Skeleton
                  animation="wave"
                  variant="rectangular"
                  width={"100%"}
                  height={"100%"}
                />
              </Grid>
              <Grid height={470} item xs={12} sm={6} md={4} lg={3} xl={2}>
                <Skeleton
                  animation="wave"
                  variant="rectangular"
                  width={"100%"}
                  height={"100%"}
                />
              </Grid>
              <Grid height={470} item xs={12} sm={6} md={4} lg={3} xl={2}>
                <Skeleton
                  animation="wave"
                  variant="rectangular"
                  width={"100%"}
                  height={"100%"}
                />
              </Grid>
            </>
          )}
        </Grid>
      </Content>
      <Appbar />
    </>
  );
}

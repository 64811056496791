import React, { useEffect, useState } from "react";
import Appbar from "../../Components/Appbar";
import Content from "../../Components/Content";
import { useSelector } from "react-redux";
import type { AppDispatch, RootState } from "../../Store/index";
import { useParams, useNavigate } from "react-router-dom";
import Carrossel from "../../Components/Carousel";
import ModeEditOutlineIcon from "@mui/icons-material/ModeEditOutline";
import FindReplaceIcon from "@mui/icons-material/FindReplace";

import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Alert,
  Box,
  Button,
  Grid,
  IconButton,
  TextField,
  Typography,
  MenuItem,
  Slider,
  Skeleton,
  CircularProgress,
} from "@mui/material";
import { LoadingButton } from "@mui/lab";
import { Vehicle, Despesa, Prestador, Venda, Vendedor } from "../../types";
import TableDespesas from "../../Components/TableDespesas";
import {
  addDespesa,
  addVenda,
  deleteDespesa,
  deleteVehicle,
  getVehicleByPlate,
  listenToUpdates,
  updateVehicle,
} from "../../Services/FirebaseDatabase";
import SendIcon from "@mui/icons-material/Send";
import ShoppingCartIcon from "@mui/icons-material/ShoppingCart";
import ShoppingCartCheckoutIcon from "@mui/icons-material/ShoppingCartCheckout";
import InfoIcon from "@mui/icons-material/Info";
import LocalAtmIcon from "@mui/icons-material/LocalAtm";
import CalculateIcon from "@mui/icons-material/Calculate";
import SaveIcon from "@mui/icons-material/Save";
import {
  fetchPrestadores,
  fetchVehicles,
  fetchVendas,
  fetchVendedores,
} from "../../Store/Veículos";
import { useDispatch } from "react-redux";
import { toNumber, toReais } from "../../Services/AuxiliarFunctions";
import CameraAltIcon from "@mui/icons-material/CameraAlt";
import Picture from "../../Components/Picture";
import { uploadImages } from "../../Services/FirebaseStorage";
import Checkbox from "@mui/material/Checkbox";
import FormControlLabel from "@mui/material/FormControlLabel";
import CheckboxOpcionais from "../../Components/CheckboxOpcionais";
import { Carousel } from "react-responsive-carousel";
import { FipeHistoryChart } from "../../Components/Charts/FipeHistoryChart.";
import { getPriceHistory } from "../../Services/Fipe";
import LanguageRoundedIcon from "@mui/icons-material/LanguageRounded";
import GradeRoundedIcon from "@mui/icons-material/GradeRounded";
import StarBorderPurple500OutlinedIcon from "@mui/icons-material/StarBorderPurple500Outlined";
import PublishedWithChangesOutlinedIcon from "@mui/icons-material/PublishedWithChangesOutlined";
import UnpublishedOutlinedIcon from "@mui/icons-material/UnpublishedOutlined";
import DeleteIcon from "@mui/icons-material/Delete";

export default function Car() {
  const dispatch = useDispatch<AppDispatch>();
  const navigate = useNavigate();

  const prestadoresSlice = useSelector((state: RootState) => state.prestadores);
  const vehicles = useSelector((state: RootState) => state.vehicles);
  const vendedoresSlice = useSelector(
    (state: RootState) => state.vendedores.data
  );
  const { placa } = useParams();
  const [car, setCar] = useState<Vehicle>();
  const [index, setIndex] = useState<number>();
  const [despesas, setDespesas] = useState<any>();
  const [form, setForm] = useState<Vehicle>({ ...(car as Vehicle) });
  const [formVenda, setFormVenda] = useState({} as Venda);
  const [attLoading, setAttLoading] = useState<boolean>(false);
  const [despesasLoading, setDespesasLoading] = useState<boolean>(false);
  const [loadingFipeHistory, setLoadingFipeHistory] = useState(false);
  const [newDespesa, setNewDespesa] = useState<Despesa>({} as Despesa);
  const [success, setSuccess] = useState<boolean>(false);
  const [successDespesas, setSuccessDespesas] = useState<boolean>(false);
  const [valorCompraNumber, setValorCompraNumber] = useState<number>();
  const [somaDespesas, setSomaDespesas] = useState<number>();
  const [valorVenda, setValorVenda] = useState<any>();
  const [inputMargem, setInputMargem] = useState<number>(0);
  const [prestadores, setPrestadores] = useState<Array<Prestador>>();
  const [errorVenda, setErrorVenda] = useState("");
  const [colorValorVenda, setColorValorVenda] = useState<
    "brown" | "rgb(242, 165, 2)" | "green"
  >();
  const [newfiles, setNewFiles] = useState<File[]>([]);
  const [newFotosUrls, setNewFotosUrls] = useState<string[]>();
  const [loadingAddImage, setLoadingAddImage] = useState(false);
  const [errorAtt, setErrorAtt] = useState<string | null>(null);
  const [disabledVenda, setDisabledVenda] = useState(true);
  const [checkedTroca, setCheckedTroca] = React.useState(true);
  const [AuxValorTroca, setAuxValorTroca] = useState(false);
  const [publishForm, setPublishForm] = useState({
    destaque: false,
  });
  const [alertPublish, setalertPublish] = useState("");

  const onInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    let value = e.target.value;
    const id = e.target.id;
    setForm({
      ...form,
      [id]: value,
    });
  };

  const trocaChange = (
    event: React.SyntheticEvent<Element, Event>,
    checked: boolean
  ) => {
    setCheckedTroca(checked);
  };

  const onVendaChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    let id = e.target.id;
    if (!id) id = "vendedor";
    setFormVenda({
      ...formVenda,
      [id]: e.target.value,
    });
  };

  const onDespesaChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    let id = e.target.id;
    if (!id) {
      id = "prestador";
    }
    let value = e.target.value;
    if (id === "valor") {
      value = e.target.value.replace(/\D+/g, "");
    }

    setNewDespesa({
      ...newDespesa,
      [id]: value,
    });
  };

  async function publishSite() {
    if (!car?.fotos) {
      setErrorAtt("Não é possível publicar o carro sem fotos.");
      return;
    }
    setAttLoading(true);
    setErrorAtt("");
    const newVehicle = {
      ...car!,
      online: true,
      destaque: publishForm.destaque,
    };
    await updateVehicle(newVehicle);
    setCar(newVehicle);
    setAttLoading(false);
    setalertPublish("Publicado com Sucesso");
  }

  async function unpublishSite() {
    setAttLoading(true);
    const newVehicle = {
      ...car!,
      online: false,
      destaque: publishForm.destaque,
    };
    await updateVehicle(newVehicle);
    setCar(newVehicle);
    setAttLoading(false);
    setalertPublish("Despublicado com Sucesso");
  }
  async function buscarHsitorico() {
    setLoadingFipeHistory(true);
    const priceHistory = await getPriceHistory(
      car?.fipeInfo.codeFipe!,
      car!.ano
    );
    priceHistory.push({
      month: car?.fipeInfo.referenceMonth,
      price: car?.fipeInfo.price,
    });
    const newVehicle = {
      ...car,
      priceHistory: priceHistory,
    };
    updateVehicle(newVehicle as Vehicle);
    setLoadingFipeHistory(false);
    setCar(newVehicle as Vehicle);
  }

  async function onClickAtualizar() {
    setErrorAtt(null);
    setSuccess(false);
    try {
      if (!form.cor || !form.valorCompra || !form.placa || !form.renavam) {
        setErrorAtt("informe os campos necessários.");
        throw new Error();
      }

      let newValorVenda = form.valorVenda;
      let newValorCompra = form.valorCompra;

      if (
        !isNaN(parseFloat(form.valorVenda!)) &&
        isFinite(parseFloat(form.valorVenda!))
      ) {
        newValorVenda = Number(form.valorVenda!).toLocaleString("pt-BR", {
          style: "currency",
          currency: "BRL",
        });
      }
      setSuccess(false);
      setAttLoading(true);
      await updateVehicle({
        ...form,
        valorCompra: newValorCompra,
        valorVenda: newValorVenda,
        despesas: despesas ? despesas : [],
        fotos: car!.fotos,
        opcionais: getSelectedCheckboxes(),
      });
      listenToUpdates().then((vehicles) => {
        if (vehicles[index!]) {
          setCar(vehicles[index!]);
          setForm(vehicles[index!]);
          setDespesas(vehicles[index!].despesas);
          setValorCompraNumber(
            Number(
              vehicles[index!].valorCompra
                .replace("R$", "")
                .split(",")[0]
                .replace(".", "")
            )
          );
        } else setDespesas([]);
        const attValorVenda = toNumber(form.valorVenda as string);
        setValorVenda(toNumber(form.valorVenda as string));
        setInputMargem(
          Number(
            Number(
              (attValorVenda / (somaDespesas! + valorCompraNumber!) - 1) * 100
            ).toFixed(2)
          )
        );
      });
      setAttLoading(false);
      setSuccess(true);
    } catch {
      (err: any) => {
        console.log(err);
      };
    }
  }

  async function onClickAddDespesas() {
    setSuccessDespesas(false);
    setDespesasLoading(true);
    await addDespesa(newDespesa, car!.placa);
    setNewDespesa({ ...newDespesa, descrição: "", valor: "", data: "" });
    setDespesasLoading(false);
    setSuccessDespesas(true);
    somarDespesas();
  }

  async function onClickDelete(id: string | undefined) {
    if (!id || !car) {
      return;
    }
    await deleteDespesa(id, car?.placa!);
    listenToUpdates().then((vehicles) => {
      if (vehicles[index!].despesas) {
        setDespesas(vehicles[index!].despesas);
      } else setDespesas([]);
    });
  }

  async function onClickVenda() {
    setErrorVenda("");
    setAttLoading(true);
    await deleteVehicle(car!.placa);
    await addVenda(formVenda, car!.placa, car!);
    navigate("/vendas");
  }

  async function onClickDeleteVehicle() {
    if (confirm("Deseja mesmo deletar o veículo?")) {
      deleteVehicle(car!.placa);
      navigate("/cars");
      dispatch(fetchVehicles());
    }
  }

  function calcLucro() {
    const lucro =
      Number(Number(formVenda.valor) + Number(formVenda.ValorCompraTroca)) -
      (somaDespesas! + valorCompraNumber!);
    setFormVenda({
      ...formVenda,
      lucro: lucro,
    });
    setAuxValorTroca(true);
  }

  const handleFileUpload = (e: any) => {
    const files = e.target.files;
    if (files) {
      let urlimages = [];
      let filesarray = [];

      for (let i = 0; i < files.length; i++) {
        const file = files[i];
        const url = URL.createObjectURL(file);
        urlimages.push(url);
        filesarray.push(file);
      }
      setNewFiles(filesarray);
    }
  };

  async function uploadNewFiles() {
    setLoadingAddImage(true);
    const urls: any = await uploadImages(newfiles);
    setNewFotosUrls(urls);
    const arrayantigo = [...car!.fotos] || [];
    urls.forEach((file: string) => arrayantigo.push(file));
    setCar({
      ...car!,
      fotos: arrayantigo,
    });
    setLoadingAddImage(false);
  }

  function toMiniaturaClick(url: string) {
    const newFotos = car!.fotos.filter((foto) => foto != url);
    newFotos.unshift(url);
    setCar({
      ...car!,
      fotos: newFotos,
    });
    setForm({
      ...form,
      fotos: newFotos,
    });
  }

  const onChangeinputMargem = (e: Event, value: number | number[]) => {
    if (value === null) return;
    setInputMargem(Number(value));
    const newValorvenda =
      (valorCompraNumber! + somaDespesas!) * (1 + Number(value) / 100);
    setForm({
      ...form,
      valorVenda: toReais(newValorvenda),
    });
    setValorVenda(newValorvenda);
  };

  function somarDespesas() {
    let soma = 0;
    for (let key in despesas) {
      soma += Number(despesas[key].valor);
    }
    setSomaDespesas(soma);
    setValorVenda(
      (valorCompraNumber! + soma) * (1 + Number(inputMargem) / 100)
    );
    return soma;
  }

  function onClickRemoveImage(url: string) {
    const newFotos = car!.fotos.filter((foto) => foto != url);
    setCar({
      ...car!,
      fotos: newFotos,
    });
    setForm({
      ...form,
      fotos: newFotos,
    });
  }

  function getSelectedCheckboxes(): string[] {
    const checkboxes = document.querySelectorAll<HTMLInputElement>(
      'input[type="checkbox"]'
    );
    const selectedCheckboxes: string[] = [];

    checkboxes.forEach((checkbox) => {
      if (checkbox.checked) {
        selectedCheckboxes.push(checkbox.labels![0].textContent ?? "");
      }
    });
    return selectedCheckboxes.filter((checkbox) => checkbox != "Sem troca");
  }

  useEffect(() => {
    listenToUpdates()
      .then((vehicles) => {
        setDespesas(vehicles[index!].despesas);
      })
      .catch((error) => {
        console.error(error);
      });
  }, [success, despesasLoading]);

  useEffect(() => {
    async function pegarVeiculopelaPlaca() {
      const find = await getVehicleByPlate(placa!);
      if (!find) throw new Error("Veículo com essa placa não encontrado");
      setCar(find);
      setIndex(vehicles.data.findIndex((i) => i.placa == placa));
      setDespesas(find.despesas);
      setForm({
        ...find,
      });
      const valorCompraN = Number(
        find.valorCompra.replace("R$", "").split(",")[0].replace(".", "")
      );
      const valorVendaN = Number(
        find.valorVenda?.replace("R$", "").split(",")[0].replace(".", "")
      );
      setValorCompraNumber(valorCompraN);
      let soma = 0;
      for (let key in find.despesas) {
        soma += Number(find.despesas[key].valor);
      }
      setSomaDespesas(soma);
      setValorVenda(valorVendaN);
      const custo = soma + valorCompraN;
      setInputMargem(
        Number(Number((valorVendaN / custo - 1) * 100).toFixed(2))
      );
      setPrestadores(prestadoresSlice.data);
    }
    pegarVeiculopelaPlaca();
  }, [dispatch]);

  useEffect(() => {
    somarDespesas();
  }, [inputMargem, despesas]);

  useEffect(() => {
    const custo = somarDespesas() + valorCompraNumber!;
    if (valorVenda < custo * 1.05) setColorValorVenda("brown");
    if (valorVenda >= custo * 1.05 && valorVenda < custo * 1.1)
      setColorValorVenda("rgb(242, 165, 2)");
    if (valorVenda >= custo * 1.1) setColorValorVenda("green");
  }, [valorVenda]);

  useEffect(() => {
    if (
      formVenda.comprador &&
      formVenda.data &&
      formVenda.lucro &&
      formVenda.valor &&
      formVenda.vendedor
    )
      setDisabledVenda(false);
  }, [formVenda]);

  useEffect(() => {
    if (!localStorage.getItem("user")) navigate("/");
    dispatch(fetchVehicles());
    dispatch(fetchVendas());
    dispatch(fetchPrestadores());
    dispatch(fetchVendedores());
  }, [dispatch]);

  if (!car)
    return (
      <Box
        sx={{
          display: "flex",
          width: "100vw",
          justifyContent: "center",
          marginTop: "20%",
        }}
      >
        <CircularProgress />
      </Box>
    );
  return (
    <>
      <Content>
        <Grid container flexDirection={"row-reverse"}>
          <Grid container xs={12} lg={7}>
            <Grid xs={12} padding={2} item>
              <Box marginBottom={3} textAlign={"center"}>
                <Typography variant={"h3"}>{car?.fipeInfo.model}</Typography>
              </Box>

              <Accordion
                sx={{
                  marginBottom: "2%",
                }}
              >
                <AccordionSummary>
                  <Button
                    sx={{ width: "100%" }}
                    size="large"
                    color="primary"
                    component="label"
                  >
                    <InfoIcon fontSize="large" />
                  </Button>
                </AccordionSummary>

                <AccordionDetails>
                  <Box
                    component="form"
                    noValidate
                    autoComplete="off"
                    textAlign={"center"}
                    sx={{
                      "& .MuiTextField-root": { m: 1, my: 2 },
                    }}
                  >
                    <TextField
                      sx={{
                        width: "25%",
                      }}
                      variant="outlined"
                      required
                      value={car?.fipeInfo.brand}
                      disabled
                      id="marca"
                      label="Marca"
                    ></TextField>

                    <TextField
                      sx={{
                        width: "65%",
                      }}
                      variant="outlined"
                      value={car?.fipeInfo.model}
                      disabled
                      id="modelo"
                      label="Modelo"
                    ></TextField>

                    <TextField
                      sx={{
                        width: "29%",
                      }}
                      required
                      disabled
                      value={car?.fipeInfo.modelYear}
                      id="ano"
                      label="Ano"
                      variant="outlined"
                    ></TextField>

                    <TextField
                      sx={{
                        width: "29.25%",
                      }}
                      variant="outlined"
                      label="Placa"
                      onChange={onInputChange}
                      value={form?.placa}
                      id="placa"
                    />
                    <TextField
                      sx={{
                        width: "29.25%",
                      }}
                      value={form?.cor}
                      onChange={onInputChange}
                      variant="outlined"
                      id="cor"
                      label="Cor"
                    />
                    <TextField
                      sx={{
                        width: "45%",
                      }}
                      id="renavam"
                      onChange={onInputChange}
                      value={form?.renavam}
                      variant="outlined"
                      label="Renavam"
                    />
                    <TextField
                      sx={{ width: "45%" }}
                      value={form?.valorCompra}
                      onChange={onInputChange}
                      variant="outlined"
                      type="text"
                      id="valorCompra"
                      label="Valor da Compra"
                    />
                    <TextField
                      value={form?.km}
                      id="km"
                      onChange={onInputChange}
                      label={"Quilometragem"}
                      sx={{
                        width: "92.5%",
                      }}
                    />
                    <TextField
                      sx={{
                        width: "35%",
                      }}
                      variant="outlined"
                      label="Data Compra"
                      disabled
                      value={form?.dataCompra}
                      id="dataCompra"
                    />
                    <TextField
                      id="valorVenda"
                      variant="outlined"
                      value={form?.valorVenda}
                      label="Preço de Venda"
                      sx={{
                        width: "55%",
                        color: "black",
                      }}
                      onChange={onInputChange}
                    />

                    <CheckboxOpcionais
                      selecteds={car?.opcionais ? car?.opcionais : []}
                    />

                    <LoadingButton
                      sx={{
                        marginTop: "3%",
                      }}
                      size="large"
                      color="primary"
                      onClick={onClickAtualizar}
                      loadingPosition="start"
                      startIcon={<ModeEditOutlineIcon />}
                      variant="contained"
                      loading={attLoading}
                    >
                      Atualizar
                    </LoadingButton>
                    <IconButton
                      onClick={onClickDeleteVehicle}
                      sx={{
                        position: "absolute",
                        right: 10,
                        marginTop: "3%",
                      }}
                    >
                      <DeleteIcon />
                    </IconButton>

                    {success ? (
                      <Alert
                        sx={{
                          width: "300px",
                          marginLeft: "27%",
                        }}
                        severity="success"
                      >
                        Atualizado com Sucesso.
                      </Alert>
                    ) : (
                      <></>
                    )}

                    {errorAtt ? (
                      <Alert
                        sx={{
                          width: "300px",
                          marginLeft: "27%",
                        }}
                        severity="error"
                      >
                        {errorAtt}
                      </Alert>
                    ) : (
                      <></>
                    )}
                  </Box>
                </AccordionDetails>
              </Accordion>

              <Accordion
                sx={{
                  marginBottom: "2%",
                }}
              >
                <AccordionSummary>
                  {/* {JSON.stringify(form)} */}
                  <Button
                    sx={{ width: "100%" }}
                    size="large"
                    color="primary"
                    component="label"
                  >
                    <CameraAltIcon fontSize="large" />
                  </Button>
                </AccordionSummary>

                <AccordionDetails sx={{ textAlign: "center" }}>
                  <Box
                    sx={{
                      display: "grid",
                      gridTemplateColumns: "1fr 1fr 1fr 1fr",
                      gridGap: "20px",
                      "@media (max-width: 768px)": {
                        gridTemplateColumns: "1fr 1fr",
                        gridGap: "30px",
                      },
                    }}
                  >
                    {car?.fotos ? (
                      car!.fotos.map((foto, i) =>
                        i === 0 ? (
                          <Picture
                            toMiniaturaClick={toMiniaturaClick}
                            removeClick={onClickRemoveImage}
                            type="miniatura"
                            src={foto}
                          />
                        ) : (
                          <Picture
                            toMiniaturaClick={toMiniaturaClick}
                            removeClick={onClickRemoveImage}
                            type="outras"
                            src={foto}
                          />
                        )
                      )
                    ) : (
                      <></>
                    )}
                  </Box>
                  <Box display={"flex"} justifyContent={"center"}>
                    <TextField
                      onChange={handleFileUpload}
                      inputProps={{ multiple: true }}
                      sx={{
                        marginTop: "2%",
                        marginLeft: "5%",
                        width: "80%",
                      }}
                      type={"file"}
                    ></TextField>
                    <LoadingButton
                      sx={{
                        marginTop: "3%",
                      }}
                      size="large"
                      color="primary"
                      onClick={uploadNewFiles}
                      loadingPosition="start"
                      startIcon={<SendIcon />}
                      variant="text"
                      loading={loadingAddImage}
                    ></LoadingButton>
                  </Box>

                  <LoadingButton
                    sx={{
                      marginTop: "3%",
                    }}
                    size="large"
                    color="primary"
                    onClick={onClickAtualizar}
                    loadingPosition="start"
                    startIcon={<SaveIcon />}
                    variant="contained"
                    loading={loadingAddImage}
                  >
                    Atualizar
                  </LoadingButton>
                  {success ? (
                    <Alert
                      sx={{
                        width: "300px",
                        marginLeft: "27%",
                      }}
                      severity="success"
                    >
                      Atualizado com Sucesso.
                    </Alert>
                  ) : (
                    <></>
                  )}

                  {errorAtt ? (
                    <Alert
                      sx={{
                        width: "300px",
                        marginLeft: "27%",
                      }}
                      severity="error"
                    >
                      {errorAtt}
                    </Alert>
                  ) : (
                    <></>
                  )}
                </AccordionDetails>
              </Accordion>

              <Accordion
                sx={{
                  marginBottom: "2%",
                }}
              >
                <AccordionSummary>
                  <Button
                    sx={{ width: "100%" }}
                    size="large"
                    color="primary"
                    component="label"
                  >
                    <LocalAtmIcon fontSize="large" />
                  </Button>
                </AccordionSummary>

                <AccordionDetails
                  sx={{
                    textAlign: "center",
                  }}
                >
                  <Typography variant="h5" gutterBottom>
                    Cadastrar Despesa
                  </Typography>

                  <TextField
                    value={newDespesa.descrição}
                    sx={{ marginLeft: "1%", width: "50%" }}
                    onChange={onDespesaChange}
                    id="descrição"
                    label="Descrição"
                    variant="standard"
                  />

                  <TextField
                    onChange={onDespesaChange}
                    id="valor"
                    value={newDespesa.valor}
                    label="Valor"
                    variant="standard"
                    sx={{ marginLeft: "4%", width: "40%" }}
                  />

                  <TextField
                    id="data"
                    value={newDespesa.data}
                    onChange={onDespesaChange}
                    type={"date"}
                    label="."
                    variant="standard"
                    sx={{ marginLeft: "1%", width: "50%" }}
                  />
                  <TextField
                    select
                    id="prestador"
                    value={newDespesa.prestador}
                    onChange={onDespesaChange}
                    label="Prestador"
                    variant="standard"
                    sx={{ marginLeft: "4%", width: "40%" }}
                  >
                    {prestadores ? (
                      prestadores.map((p) => (
                        <MenuItem key={p.id} value={p.nome}>
                          {p.nome}
                        </MenuItem>
                      ))
                    ) : (
                      <></>
                    )}
                  </TextField>

                  <LoadingButton
                    sx={{
                      marginTop: "3%",
                    }}
                    onClick={onClickAddDespesas}
                    loading={despesasLoading}
                    color="primary"
                    aria-label="upload picture"
                    component="label"
                  >
                    <SendIcon fontSize="medium" />
                  </LoadingButton>
                  {successDespesas ? (
                    <Alert
                      sx={{
                        width: "100%",
                      }}
                      severity="success"
                    >
                      Cadastrado com Sucesso!.
                    </Alert>
                  ) : (
                    <></>
                  )}
                </AccordionDetails>
              </Accordion>

              <Accordion
                sx={{
                  marginBottom: "2%",
                }}
              >
                <AccordionSummary>
                  <Button
                    sx={{ width: "100%" }}
                    size="large"
                    color="primary"
                    component="label"
                  >
                    <CalculateIcon fontSize="large" />
                  </Button>
                </AccordionSummary>
                <AccordionDetails>
                  <Grid container>
                    <Grid item xs={12} md={7}>
                      <Typography m={1} variant="h5">
                        Valor Compra: <strong> {car?.valorCompra} </strong>
                      </Typography>
                      <Typography m={1} variant="h5">
                        Total Despesas:{" "}
                        <strong style={{ color: "red" }}>
                          {" "}
                          {somaDespesas?.toLocaleString("pt-BR", {
                            style: "currency",
                            currency: "BRL",
                          })}
                        </strong>
                      </Typography>
                      <Typography m={1} variant="h5">
                        Valor Fipe:{" "}
                        <strong style={{ color: "midnightblue" }}>
                          {" "}
                          {car?.fipeInfo.price}
                        </strong>
                      </Typography>
                    </Grid>
                    <Grid item xs={12} md={5}>
                      <Typography m={1} variant="h5">
                        Margem de Lucro: {`${inputMargem ? inputMargem : ""}%`}
                        <Box width={300}>
                          <Slider
                            defaultValue={50}
                            aria-label="Default"
                            valueLabelDisplay="auto"
                            onChange={onChangeinputMargem}
                          />
                        </Box>
                      </Typography>
                    </Grid>
                    <Grid item xs={12} md={7}>
                      <Typography m={1} variant="h5">
                        Valor de Venda:{" "}
                        <strong style={{ color: colorValorVenda }}>
                          {" "}
                          {typeof toReais(valorVenda) == "number"
                            ? toReais(valorVenda)
                            : form.valorVenda}{" "}
                        </strong>
                        <IconButton color="primary" onClick={onClickAtualizar}>
                          <SaveIcon fontSize="medium" />
                        </IconButton>
                      </Typography>
                    </Grid>
                  </Grid>
                </AccordionDetails>
              </Accordion>

              <Accordion
                sx={{
                  marginBottom: "2%",
                }}
              >
                <AccordionSummary>
                  <Button
                    sx={{ width: "100%" }}
                    size="large"
                    color="primary"
                    component="label"
                  >
                    <LanguageRoundedIcon fontSize="large" />
                  </Button>
                </AccordionSummary>
                <AccordionDetails>
                  <Grid container>
                    <Grid textAlign={"center"} item xs={12}>
                      <FormControlLabel
                        label="Em Destaque"
                        control={
                          <Checkbox
                            onChange={(e: any, value: boolean) =>
                              setPublishForm({
                                ...publishForm,
                                destaque: value,
                              })
                            }
                            icon={
                              <StarBorderPurple500OutlinedIcon
                                sx={{ color: "rgb(255, 196, 0)" }}
                              />
                            }
                            checkedIcon={
                              <GradeRoundedIcon
                                sx={{ color: "rgb(255, 196, 0)" }}
                              />
                            }
                          />
                        }
                      />
                      {car?.online ? (
                        <LoadingButton
                          loading={attLoading}
                          onClick={unpublishSite}
                          startIcon={<UnpublishedOutlinedIcon />}
                          variant="contained"
                        >
                          Despublicar
                        </LoadingButton>
                      ) : (
                        <LoadingButton
                          loading={attLoading}
                          onClick={publishSite}
                          startIcon={<PublishedWithChangesOutlinedIcon />}
                          variant="contained"
                        >
                          Publicar
                        </LoadingButton>
                      )}
                      {alertPublish && (
                        <>
                          <br />
                          <Alert severity="success">{alertPublish}</Alert>
                        </>
                      )}
                      {errorAtt ? (
                        <Alert severity="error">{errorAtt}</Alert>
                      ) : (
                        <></>
                      )}
                      <></>
                    </Grid>
                  </Grid>
                </AccordionDetails>
              </Accordion>

              <Accordion
                sx={{
                  marginBottom: "2%",
                }}
              >
                <AccordionSummary>
                  <Button
                    sx={{ width: "100%" }}
                    size="large"
                    color="primary"
                    component="label"
                  >
                    <ShoppingCartIcon fontSize="large" />
                  </Button>
                </AccordionSummary>
                <AccordionDetails>
                  <Typography variant="h5" gutterBottom>
                    Executar Venda
                  </Typography>
                  <Box
                    component="form"
                    sx={{
                      "& .MuiTextField-root": { m: 1, width: "40%" },
                    }}
                    noValidate
                    autoComplete="off"
                  >
                    <TextField
                      onChange={onVendaChange}
                      id="valor"
                      label="Valor em Dinheiro"
                      variant="standard"
                    />
                    <TextField
                      onChange={onVendaChange}
                      id="comprador"
                      label="Comprador"
                      variant="standard"
                    />
                    <TextField
                      onChange={onVendaChange}
                      id="data"
                      type={"date"}
                      label="."
                      variant="standard"
                    />
                    <TextField
                      onChange={onVendaChange}
                      id="vendedor"
                      select={true}
                      label="Vendedor"
                      variant="standard"
                    >
                      {vendedoresSlice.map((vendedor: Vendedor) => (
                        <MenuItem key={vendedor.nome} value={vendedor.nome}>
                          {vendedor.nome}
                        </MenuItem>
                      ))}
                    </TextField>

                    <Box my={5}>
                      <Typography variant="h6">
                        Troca: &#8287; &#8287;&#8287;&#8287;&#8287;&#8287;
                        <FormControlLabel
                          id="semtroca"
                          onChange={trocaChange}
                          control={<Checkbox defaultChecked />}
                          label="Sem troca"
                        />
                      </Typography>

                      <TextField
                        disabled={checkedTroca}
                        onChange={onVendaChange}
                        id="descriçãoTroca"
                        label="Descrição do Veículo"
                        variant="standard"
                      />
                      <TextField
                        disabled={checkedTroca}
                        onChange={onVendaChange}
                        id="ValorCompraTroca"
                        label="Valor Avaliado"
                        variant="standard"
                        helperText={
                          AuxValorTroca &&
                          "(Cadastre o veículo posteriormente com esse valor)"
                        }
                      />
                    </Box>
                    <TextField
                      onChange={onVendaChange}
                      InputProps={{
                        readOnly: true,
                        style: {
                          color: formVenda.lucro > 0 ? "green" : "red",
                        },
                      }}
                      value={
                        formVenda.lucro
                          ? Number(formVenda.lucro).toLocaleString("pt-BR", {
                              style: "currency",
                              currency: "BRL",
                            })
                          : ""
                      }
                      id="lucro"
                      label={formVenda.lucro ? "." : "Lucro (*Calcular)"}
                      variant="standard"
                    ></TextField>

                    <IconButton
                      color="primary"
                      onClick={calcLucro}
                      size="large"
                    >
                      <CalculateIcon fontSize="large" />
                    </IconButton>
                    <LoadingButton
                      size="small"
                      onClick={onClickVenda}
                      loading={attLoading}
                      color="primary"
                      aria-label="upload picture"
                      component="label"
                      disabled={disabledVenda}
                    >
                      <ShoppingCartCheckoutIcon fontSize="large" />
                    </LoadingButton>
                  </Box>

                  {errorVenda ? (
                    <Alert
                      sx={{
                        width: "100%",
                      }}
                      severity="error"
                    >
                      {errorVenda}
                    </Alert>
                  ) : (
                    <></>
                  )}
                </AccordionDetails>
              </Accordion>
            </Grid>
          </Grid>

          <Grid container xs={12} lg={5}>
            <Grid
              xs={12}
              item
              sx={{
                overflow: "hidden",
              }}
            >
              {car && car.fotos?.length > 0 ? (
                <Carousel>
                  {car?.fotos.map((foto) => (
                    <div>
                      <img src={foto} alt="" />
                    </div>
                  ))}
                </Carousel>
              ) : (
                <></>
              )}

              <TableDespesas
                onClickDelete={onClickDelete}
                placa={car?.placa}
                data={despesas}
              />

              <Box textAlign={"center"} height={400} mb={10}>
                <FipeHistoryChart history={car?.priceHistory!} />
                <LoadingButton
                  size="small"
                  startIcon={<FindReplaceIcon />}
                  loadingPosition="start"
                  loading={loadingFipeHistory}
                  onClick={buscarHsitorico}
                  sx={{ color: "#08345a" }}
                  variant="text"
                >
                  Buscar Histórico
                </LoadingButton>
              </Box>
            </Grid>
          </Grid>
        </Grid>
      </Content>
      <Appbar />
    </>
  );
}

import Appbar from "../../Components/Appbar";
import Content from "../../Components/Content";
import { useState, useEffect } from "react";
import { styled } from "@mui/material/styles";
import TableCell, { tableCellClasses } from "@mui/material/TableCell";
import TableRow from "@mui/material/TableRow";
import { getVendas } from "../../Services/FirebaseDatabase";
import { Venda } from "../../types";
import TableVendas from "../../Components/TableVendas";
import { useSelector } from "react-redux";
import { AppDispatch, RootState } from "../../Store";
import { fetchVendas } from "../../Store/Veículos";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";

export default function Vendas() {
  const navigate = useNavigate();
  const dispatch = useDispatch<AppDispatch>();
  const vendasSlice = useSelector((state: RootState) => state.vendas.data);

  useEffect(() => {
    if (!localStorage.getItem("user")) navigate("/");
    dispatch(fetchVendas());
  }, [dispatch]);

  return (
    <>
      <Content>
        <TableVendas vendas={Object.values(vendasSlice).reverse()} />
      </Content>
      <Appbar />
    </>
  );
}

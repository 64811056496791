import { Typography } from "@mui/material";
import dayjs from "dayjs";
import React, { PureComponent, useEffect, useState } from "react";
import { useSelector } from "react-redux";
import {
  AreaChart,
  Area,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  ResponsiveContainer,
} from "recharts";
import { toNumber, toReais } from "../../Services/AuxiliarFunctions";
import { RootState } from "../../Store";
import { historyFipePrice, Vehicle, Venda } from "../../types";

interface DataObject {
  month: string;
  price: number;
}
export function FipeHistoryChart(props: { history: historyFipePrice[] }) {
  const [data, setData] = useState<DataObject[]>([]);
  const [domains, setDomains] = useState<number[]>([]);
  const vendasSlice: Venda[] = useSelector(
    (state: RootState) => state.vendas.data
  );

  const formatterHover = (value: string | number) => {
    if (Array.isArray(value)) {
      return value.map((v) => `R$ ${toReais(v)}`).join(", ");
    }
    return `${toReais(value)}`;
  };

  useEffect(() => {
    if (!props.history) return;
    function getMinAndMaxPrice(data: historyFipePrice[]): [number, number] {
      const prices = data.map((d) =>
        Number(d.price.replace(/[^0-9,-]+/g, "").replace(",", "."))
      );
      const minPrice = Math.min(...prices);
      const maxPrice = Math.max(...prices);
      return [Number(minPrice) * 0.995, Number(maxPrice) * 1.005];
    }
    function removeDuplicateMonths(
      data: historyFipePrice[]
    ): historyFipePrice[] {
      let uniqueMonths: historyFipePrice[] = [];
      let months: dayjs.Dayjs[] = [];

      for (let i = 0; i < data.length; i++) {
        let monthString = data[i].month;
        let month = dayjs(monthString, "MMMM [de] YYYY");

        if (!months.some((m) => m.isSame(month, "month"))) {
          uniqueMonths.push(data[i]);
          months.push(month);
        }
      }

      uniqueMonths.sort((a, b) => {
        let aMonth = dayjs(a.month, "MMMM [de] YYYY");
        let bMonth = dayjs(b.month, "MMMM [de] YYYY");
        return Number(aMonth) - Number(bMonth);
      });

      return uniqueMonths;
    }
    const remove = removeDuplicateMonths(props.history);
    const refactor = remove.map((h) => {
      return {
        month: h.month,
        price: toNumber(h.price),
      };
    });
    setDomains(getMinAndMaxPrice(props.history));
    setData(refactor);
  }, [props]);

  return (
    <>
      <Typography textAlign={"center"} variant="h5" color="initial">
        Histórico de Preço Fipe
      </Typography>
      <ResponsiveContainer width="100%" height="100%">
        <AreaChart
          width={500}
          height={400}
          data={data}
          margin={{
            top: 10,
            right: 30,
            left: 40,
            bottom: 20,
          }}
        >
          <defs>
            <linearGradient id="green" x1="0" y1="0" x2="0" y2="1">
              <stop offset="50%" stopColor="#131f6c" stopOpacity={1} />
              <stop offset="200%" stopColor="#131f6c" stopOpacity={0.8} />
            </linearGradient>
          </defs>
          <CartesianGrid strokeDasharray="3 3" />
          <XAxis dataKey="month" />
          <YAxis
            domain={domains}
            tickFormatter={(value) => `${toReais(value)}`}
          />
          <Tooltip formatter={(value) => formatterHover(value as number)} />
          <Area
            strokeWidth={5}
            type="monotone"
            dataKey="price"
            stroke="#131f6c"
            fill="url(#green)"
          />
        </AreaChart>
      </ResponsiveContainer>
    </>
  );
}

import { useEffect, useState } from "react";
import Card from "@mui/material/Card";
import Collapse from "@mui/material/Collapse";
import Avatar from "@mui/material/Avatar";
import Typography from "@mui/material/Typography";
import { red } from "@mui/material/colors";
import { Venda, Vendedor } from "../../types";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Grid,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@mui/material";
import { getVendas } from "../../Services/FirebaseDatabase";
import { toReais } from "../../Services/AuxiliarFunctions";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import dayjs from "dayjs";
import { useSelector } from "react-redux";
import { RootState } from "../../Store";

interface CardVendedorProps {
  data: Vendedor;
  filterDate: string;
}

export default function CardVendedor(props: CardVendedorProps) {
  const [somaVenda, setSomaVenda] = useState<number>();
  const [vendas, setVendas] = useState<Venda[]>();
  const vendasSlice = useSelector((state: RootState) => state.vendas.data);

  useEffect(() => {
    let soma = 0;
    let vendasArr: Venda[] = [];
    let arr: Venda[] = vendasSlice;
    if (props.filterDate) {
      arr = arr.filter(
        (venda) => dayjs(venda.data).format("MMMM YYYY") === props.filterDate
      );
    }
    if (arr.length === 0) {
      setVendas([]);
      setSomaVenda(0);
    }
    arr.forEach((venda) => {
      if (venda.vendedor === props.data.nome) {
        vendasArr.push(venda);

        soma += Number(venda.valor);
      }
      setVendas(vendasArr);
      setSomaVenda(soma);
    });
  }, [props]);

  return (
    <Accordion sx={{ width: "100%", marginTop: "3%" }}>
      <AccordionSummary expandIcon={<ExpandMoreIcon />}>
        <Grid container>
          <Grid xs={4}>
            <Avatar
              src={props.data.foto}
              sx={{
                width: "100px",
                height: "100px",
                marginTop: "3%",
                marginLeft: "3%",
                bgcolor: red[500],
                "@media (max-width: 768px)": {
                  width: "70px",
                  height: "70px",
                },
              }}
            ></Avatar>
          </Grid>
          <Grid item xs={8} textAlign={"left"}>
            <Typography variant="h3">{props.data.nome}</Typography>
            <Typography variant="h6">
              Vendas no mês: {somaVenda && toReais(somaVenda)}
            </Typography>
          </Grid>
        </Grid>
      </AccordionSummary>
      <AccordionDetails>
        <TableContainer component={Paper}>
          <Table size="small" aria-label="a dense table">
            <TableHead>
              <TableRow>
                <TableCell>Veículo</TableCell>
                <TableCell align="right">Valor</TableCell>
                <TableCell align="right">Data</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {vendas && vendas.length > 0 ? (
                vendas.map((v) => (
                  <TableRow>
                    <TableCell>{v.vehicle.fipeInfo.model}</TableCell>
                    <TableCell align="right">{toReais(v.valor)}</TableCell>
                    <TableCell align="right">
                      {dayjs(v.data).format("DD/MM/YYYY")}
                    </TableCell>
                  </TableRow>
                ))
              ) : (
                <></>
              )}
            </TableBody>
          </Table>
        </TableContainer>
      </AccordionDetails>
    </Accordion>
  );
}

import dayjs from "dayjs";
import {
  LineChart,
  Line,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  ResponsiveContainer,
} from "recharts";
import { useEffect, useState } from "react";
import { Venda, Vendedor } from "../../types";
import { getVendas } from "../../Services/FirebaseDatabase";
import { toReais } from "../../Services/AuxiliarFunctions";
import { useSelector } from "react-redux";
import { RootState } from "../../Store";

interface VendedorChartProps {
  filterDate: string;
}

interface DadosDeVendas {
  [nome: string]: number;
}

export default function VendedorChart(props: VendedorChartProps) {
  const vendedores: Vendedor[] = useSelector(
    (state: RootState) => state.vendedores.data
  );
  const [data, setData] = useState<DadosDeVendas[]>([]);
  const [vendedoresName, setVendedoresName] = useState(
    vendedores.map((vendedores) => vendedores.nome)
  );

  async function getSomaVendedor(filterDate: string, nome: string) {
    let soma = 0;
    const vendas = await getVendas();
    let arr: Venda[] = Object.values(vendas);
    if (filterDate) {
      arr = arr.filter(
        (venda) => dayjs(venda.data).format("MMM YYYY") === filterDate
      );
    }
    if (arr.length === 0) {
      return null;
    }
    arr.forEach((venda) => {
      if (venda.vendedor === nome) {
        soma += Number(venda.valor);
      }
    });
    return {
      month: filterDate,
      [nome]: soma,
    };
  }

  useEffect(() => {
    const startDate = dayjs(props.filterDate, "MMMM YYYY");
    const sixMonthsAgo: string[] = [];
    for (let i = 0; i < 6; i++) {
      sixMonthsAgo.push(startDate.subtract(i, "month").format("MMM YYYY"));
    }
    let array: any[] = [];
    const promises: Promise<any>[] = [];
    sixMonthsAgo.forEach((month, i) => {
      vendedoresName.forEach((v) => {
        promises.push(getSomaVendedor(month, v));
      });
    });
    Promise.all(promises).then((arrs) => {
      arrs.forEach((arr) => {
        if (arr != null) array.push(arr);
      });
      const map = new Map<string, Record<string, any>>();
      sixMonthsAgo.forEach((month) => {
        if (!map.has(month)) {
          const emptyObject: Record<string, any> = { month };
          vendedoresName.forEach((name) => {
            emptyObject[name] = 0;
          });
          map.set(month, emptyObject);
        }
      });
      array.forEach((item) => {
        if (!map.has(item.month)) {
          map.set(item.month, { month: item.month });
          vendedoresName.forEach((name) => {
            map.get(item.month)![name] = 0;
          });
        }
        vendedoresName.forEach((name) => {
          if (item.hasOwnProperty(name)) {
            map.get(item.month)![name] += item[name];
          }
        });
      });
      const dataArr = Array.from(map.values());
      console.log(dataArr);

      setData(dataArr.reverse());
    });
  }, [props]);

  const formatterHover = (value: string | number) => {
    if (Array.isArray(value)) {
      return value.map((v) => `R$ ${toReais(v)}`).join(", ");
    }
    return `${toReais(value)}`;
  };

  const colors = [
    "rgb(202, 74, 31)",
    "rgb(111, 36, 224)",
    "rgb(242, 165, 2)",
    "rgb(235, 36, 213)",
    "rgb(67, 67, 72)",
    "rgb(124, 181, 236)",
    "rgb(249, 186, 133)",
    "rgb(128, 133, 233)",
    "rgb(0, 133, 233)",
    "rgb(165, 80, 250)",
    "rgb(202, 74,0)",
    "rgb(242, 165, 0)",
    "rgb(235, 36, 0)",
    "rgb(111, 36, 0)",
    "rgb(67, 67, 0)",
    "rgb(249, 186, 0)",
    "rgb(0, 133, 150)",
    "rgb(165, 80, 0)",
  ];

  return (
    <>
      <ResponsiveContainer width="100%" height="100%">
        <LineChart
          width={300}
          height={100}
          data={data}
          margin={{
            top: 5,
            right: 30,
            left: 50,
            bottom: 5,
          }}
        >
          <CartesianGrid strokeDasharray="5 3" />
          <XAxis dataKey="month" />
          <YAxis
            style={{ width: "400px" }}
            tickFormatter={(value) => `${toReais(value)}`}
          />
          <Tooltip formatter={(value) => formatterHover(value as number)} />
          <Legend />
          {vendedoresName.map((name, index) => (
            <Line
              strokeWidth={3}
              key={index}
              type="monotone"
              dataKey={name}
              stroke={colors[index]}
              activeDot={{ r: 5 }}
            />
          ))}
        </LineChart>
      </ResponsiveContainer>
    </>
  );
}

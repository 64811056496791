import * as React from "react";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import { Typography } from "@mui/material";
import { Vehicle } from "../types";

interface TabelaPreços {
  data: any;
}

export default function TabelaPreços(props: TabelaPreços) {
  const data = Object.values(props.data)[0] as any;

  data.sort((a: any, b: any) => {
    if (a.valorVenda < b.valorVenda) {
      return 1;
    }
    if (a.valorVenda > b.valorVenda) {
      return -1;
    }
    return 0;
  });

  return (
    <div style={{ textAlign: "center", marginTop: "3%" }}>
      <Typography m={3} variant="h4">
        <strong>{Object.keys(props.data)}</strong>{" "}
      </Typography>
      <TableContainer component={Paper}>
        <Table size="small" aria-label="a dense table">
          <TableHead>
            <TableRow>
              <TableCell style={{ fontWeight: "bold" }}>
                Nome do Veículo
              </TableCell>
              <TableCell style={{ fontWeight: "bold" }}>Opcionais</TableCell>
              <TableCell style={{ fontWeight: "bold" }}>Ano</TableCell>
              <TableCell style={{ fontWeight: "bold" }}>Cor</TableCell>
              <TableCell style={{ fontWeight: "bold" }}>Placa</TableCell>
              <TableCell style={{ fontWeight: "bold" }}>Preço</TableCell>
              <TableCell style={{ fontWeight: "bold" }}>Fipe</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {data.map((obj: Vehicle) => (
              <TableRow>
                <TableCell>{obj.fipeInfo.model}</TableCell>
                <TableCell>
                  {obj.opcionais
                    ? obj.opcionais.length < 6
                      ? obj.opcionais.join(", ")
                      : "Completo"
                    : "Básico"}
                </TableCell>
                <TableCell>{obj.ano.substr(0, 4)}</TableCell>
                <TableCell>{obj.cor}</TableCell>
                <TableCell>{obj.placa}</TableCell>
                <TableCell>{obj.valorVenda}</TableCell>
                <TableCell>{obj.fipeInfo.price}</TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </div>
  );
}

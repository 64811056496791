import { initializeApp } from "firebase/app";
import { getStorage } from "firebase/storage";
import { getDatabase } from "firebase/database";
import "firebase/storage";

// Your web app's Firebase configuration
/* const firebaseConfig = {
  apiKey: "AIzaSyBhJdsM3Rv9vr3qopgZrRYaMQFCt_krZnY",
  authDomain: "managecarsteste.firebaseapp.com",
  projectId: "managecarsteste",
  storageBucket: "managecarsteste.appspot.com",
  messagingSenderId: "188827186817",
  appId: "1:188827186817:web:84d2f0a96f5361e2713f80",
  measurementId: "G-PHVL527P09",
};
 */
/* const firebaseConfig = {
  apiKey: "AIzaSyAUEiNgtGnJWA6_EWp61ihA-E35_MD8aAA",
  authDomain: "beise-automoveis.firebaseapp.com",
  databaseURL: "https://beise-automoveis-default-rtdb.firebaseio.com",
  projectId: "beise-automoveis",
  storageBucket: "beise-automoveis.appspot.com",
  messagingSenderId: "801074060758",
  appId: "1:801074060758:web:dfa9dbdd270bbfa2591877",
  measurementId: "G-J6KTXZNF5F",
}; */

const firebaseConfig = {
  apiKey: "AIzaSyDNUmEqqm0g8K0khgIownScXhGGXpRp5S4",
  authDomain: "management-cars.firebaseapp.com",
  projectId: "management-cars",
  storageBucket: "management-cars.appspot.com",
  messagingSenderId: "342939211365",
  appId: "1:342939211365:web:91e4cf1d7c5bc89cd4988c",
  measurementId: "G-PZW9X9F4F0",
};

const app = initializeApp(firebaseConfig);
export const storage = getStorage(app);

export const database = getDatabase(app);

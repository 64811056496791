import React, {useState, useEffect} from 'react'
import { styled } from '@mui/material/styles';
import { TableCell, tableCellClasses } from '@mui/material';
import { getVendas, listenToUpdates } from '../../Services/FirebaseDatabase';
import { Despesa, Vehicle } from '../../types';
import { toNumber, toReais } from '../../Services/AuxiliarFunctions';
import dayjs from 'dayjs';
import { somarDespesasByPrestador, somarDespesasByPrestadorNameAndDate } from '../../Services/CalcFunctions';


interface totalDespesas {
    nome: string
    dataFilter : string
}

const StyledTableCell = styled(TableCell)(({ theme }) => ({
    [`&.${tableCellClasses.head}`]: {
        backgroundColor: 'rgb(164, 36, 51)',
        color: theme.palette.common.white,
    },
    [`&.${tableCellClasses.body}`]: {
        fontSize: 14,
    },
}));

export default function TotalDespesasCell(props: totalDespesas) {

    useEffect(() => {        
      somarDespesasByPrestadorNameAndDate(props.nome, props.dataFilter).then(
        res => setSoma(toReais(res))
       
        
      )
      }, [])

    const [soma, setSoma] = useState<string>('')

 return (
    <StyledTableCell align='right'>{soma}</StyledTableCell>
)

}
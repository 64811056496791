import * as React from "react";
import { red } from "@mui/material/colors";
import { createTheme } from "@mui/material/styles";

export const theme = createTheme({
  palette: {
    primary: {
      main: localStorage.getItem("color") || "rgb(0, 0, 0)",
    },
    secondary: {
      main: "rgb(223, 223, 223)",
    },
  },
});

import { createSlice, createAsyncThunk, PayloadAction } from "@reduxjs/toolkit";
import type { RootState } from "./index";
import type { Vehicle } from "../types";
import {
  getPrestadores,
  getVendas,
  getVendedores,
  listenToUpdates,
} from "../Services/FirebaseDatabase";
import axios from "axios";

const initialState = {
  loading: false,
  status: "null",
  data: [] as Vehicle[],
  fipe: {
    mes: "",
    code: 0,
    modelos: [],
    marcas: [],
  },
};

interface UserConfigState {
  color: string;
  logo: string;
}

export const fetchVehicles = createAsyncThunk(
  "vehicles/fetchVehicles",
  async () => {
    const data = await listenToUpdates();
    return data;
  }
);

export const fetchPrestadores = createAsyncThunk(
  "vehicles/getprestadores",
  async () => {
    const data = await getPrestadores();
    return data;
  }
);

export const fetchVendedores = createAsyncThunk(
  "vehicles/getvendedores",
  async () => {
    const data = await getVendedores();
    return data;
  }
);

export const fetchVendas = createAsyncThunk("vehicles/getvendas", async () => {
  const data = await getVendas();
  return data;
});

export const fetchFipe = createAsyncThunk("fipe/codigo", async () => {
  const response = await axios.get(
    "https://parallelum.com.br/fipe/api/v2/references"
  );
  const code = response.data[0].code;
  const mes = response.data[0].month;
  const marcas = await axios.get(
    "https://parallelum.com.br/fipe/api/v2/cars/brands"
  );
  return {
    marcas: marcas,
    code: code,
    mes: mes,
  };
});

const veículosSlice = createSlice({
  name: "vehicles",
  initialState: initialState,
  reducers: {
    updateVehicles(state, action) {
      state.data = action.payload.vehicles;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchVehicles.pending, (state) => {
        state.loading = true;
        state.status = "pending";
      })
      .addCase(fetchVehicles.fulfilled, (state, action) => {
        state.loading = false;
        state.status = "fullfilled";
        state.data = action.payload;
      })
      .addCase(fetchVehicles.rejected, (state) => {
        state.loading = false;
        state.status = "error";
      })
      .addCase(fetchFipe.fulfilled, (state, action) => {
        state.fipe.code = action.payload.code;
        state.fipe.mes = action.payload.mes;
        state.fipe.marcas = action.payload.marcas.data;
      });
  },
});

const PrestadoresSlice = createSlice({
  name: "prestadores",
  initialState: { data: [] },
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(fetchPrestadores.fulfilled, (state, action) => {
      state.data = Object.values(action.payload);
    });
  },
});

const VendedoresSlice = createSlice({
  name: "vendedores",
  initialState: { data: [] },
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(fetchVendedores.fulfilled, (state, action) => {
      state.data = Object.values(action.payload);
    });
  },
});

const VendasSlice = createSlice({
  name: "vendas",
  initialState: { data: [] },
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(fetchVendas.fulfilled, (state, action) => {
      state.data = Object.values(action.payload);
    });
  },
});

const inititalStateUserConfig = {
  color: localStorage.getItem("color"),
  logo: localStorage.getItem("logo"),
  email: localStorage.getItem("user"),
};

const UserConfigSlice = createSlice({
  name: "UserConfigs",
  initialState: inititalStateUserConfig,
  reducers: {
    updateUserConfig(state, action: PayloadAction<UserConfigState>) {
      const { color, logo } = action.payload;
      state.color = color;
      state.logo = logo;
    },
  },
});

export const { updateVehicles } = veículosSlice.actions;
export const selector = (state: RootState) => state.vehicles;
export const Vehicles = veículosSlice.reducer;
export const PRestadores = PrestadoresSlice.reducer;
export const Vendedores = VendedoresSlice.reducer;
export const Vendas = VendasSlice.reducer;
export const UserConfig = UserConfigSlice.reducer;
export const { updateUserConfig } = UserConfigSlice.actions;

import Typography from "@mui/material/Typography";
import { Alert, Box, Card } from "@mui/material";
import Avatar from "@mui/material/Avatar";
import { useEffect, useState } from "react";
import { Venda } from "../../types";
import AttachMoneyIcon from "@mui/icons-material/AttachMoney";
import { formatNumberToK, toNumber } from "../../Services/AuxiliarFunctions";

export default function LucroMédioPorVeículo(props: { vendas: Venda[] }) {
  const [value, setValue] = useState(0);
  const [tempoAnimacao] = useState(2000);
  const [loadingAttFipe, setLoadingAttFipe] = useState(false);
  const [statusAtt, setStatusAtt] = useState(false);

  useEffect(() => {
    function calcLucro(venda: Venda) {
      let somaDespesas = 0;
      for (let key in venda.vehicle.despesas) {
        somaDespesas += Number(venda.vehicle.despesas[key].valor);
      }
      somaDespesas += toNumber(venda.vehicle.valorCompra);
      let faturamento = Number(venda.valor);

      if (venda.ValorCompraTroca) {
        faturamento += Number(venda.ValorCompraTroca);
      }
      return faturamento - somaDespesas;
    }
    let soma = 0;
    props.vendas.forEach((venda) => (soma += calcLucro(venda)));
    const nmrVendas = props.vendas.length;
    const media = soma / nmrVendas;

    const incremento = Math.ceil(media / (tempoAnimacao / 10));
    const intervalo = setInterval(() => {
      setValue((numeroAntigo) => {
        const diferenca = media - numeroAntigo;
        const porcentagem = diferenca / media;
        let novoIncremento = media;
        if (porcentagem <= 0.1) {
          novoIncremento = Math.ceil(incremento / 4);
        }
        if (numeroAntigo < media) {
          return numeroAntigo + novoIncremento;
        }
        clearInterval(intervalo);
        return media;
      });
    }, 1);
    return () => clearInterval(intervalo);
  }, [props]);
  return (
    <Card
      sx={{
        height: 150,
        p: 1,
        mb: 2,
      }}
    >
      <Box alignItems={"center"} display={"flex"} padding={1}>
        <Avatar
          sx={{
            width: 70,
            height: 70,
            backgroundColor: "rgb(84, 204, 138)",
          }}
        >
          <AttachMoneyIcon sx={{ color: "white", fontSize: "3.5rem" }} />
        </Avatar>
        <Box>
          <Typography fontFamily={"sans-serif"} color={"grey"} mx={2}>
            Lucro Médio por Veículo
          </Typography>
          <Typography
            fontFamily={"monospace"}
            variant="h3"
            color={"black"}
            mx={2}
          >
            <strong>{formatNumberToK(value)}</strong>
          </Typography>
        </Box>
      </Box>
      <Box textAlign={"center"}></Box>
      {statusAtt ? (
        <Alert severity="success">
          Atualizado com sucesso. É necessário reiniciar a aplicação.
        </Alert>
      ) : (
        ""
      )}
    </Card>
  );
}

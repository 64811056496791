import Typography from "@mui/material/Typography";
import { Box } from "@mui/material";
import { formatNumberToK, toReais } from "../../Services/AuxiliarFunctions";
import Avatar from "@mui/material/Avatar";
import { useEffect, useState } from "react";
import AccountBalanceIcon from "@mui/icons-material/AccountBalance";

interface PatrimonioFipeProps {
  fipePrice: number;
}

export default function PatrimonioFipe(props: PatrimonioFipeProps) {
  const [value, setValue] = useState(0);
  const [tempoAnimacao] = useState(2000);

  useEffect(() => {
    const incremento = Math.ceil(props.fipePrice / (tempoAnimacao / 10));
    const intervalo = setInterval(() => {
      setValue((numeroAntigo) => {
        const diferenca = props.fipePrice - numeroAntigo;
        const porcentagem = diferenca / props.fipePrice;
        let novoIncremento = incremento;
        if (porcentagem <= 0.1) {
          novoIncremento = Math.ceil(incremento / 4);
        }
        if (numeroAntigo < props.fipePrice) {
          return numeroAntigo + novoIncremento;
        }
        clearInterval(intervalo);
        return props.fipePrice;
      });
    }, 1);
    return () => clearInterval(intervalo);
  }, [props]);
  return (
    <Box alignItems={"center"} display={"flex"} padding={1}>
      <Avatar
        sx={{ width: 70, height: 70, backgroundColor: "rgb(39, 29, 104)" }}
      >
        <AccountBalanceIcon fontSize="large" />
      </Avatar>
      <Box>
        <Typography fontFamily={"sans-serif"} color={"grey"} mx={2}>
          Patrimônio Fipe
        </Typography>
        <Typography
          fontFamily={"monospace"}
          variant="h3"
          color={"black"}
          mx={2}
        >
          <strong>{formatNumberToK(value)}</strong>
        </Typography>
      </Box>
    </Box>
  );
}

import React from "react";
import ReactDOM from "react-dom/client";
import { createBrowserRouter, RouterProvider } from "react-router-dom";
import App from "./App";
import "./index.css";
import Cars from "./private/cars/Cars";
import { Provider } from "react-redux";
import { store } from "./Store/index";
import { theme } from "./Theme";
import { ThemeProvider } from "@mui/material/styles";
import Car from "./private/cars/Car";
import Prestadores from "./private/Prestadores/Prestadores";
import Vendas from "./private/Vendas/Vendas";
import Tabela from "./private/Tabela/Tabela";
import Dashboard from "./private/Dashboard/Dashboard";
import Vendedores from "./private/Vendedores/Vendedores";
import AddUserAdmnin from "./Admin/AddUser";
import SoldCar from "./private/cars/SoldCar";

const router = createBrowserRouter([
  {
    path: "/",
    element: <App />,
  },
  {
    path: "/adm",
    element: <AddUserAdmnin />,
  },
  {
    path: "/cars",
    element: <Cars />,
  },
  {
    path: "/vendas",
    element: <Vendas />,
  },
  {
    path: "/prestadores",
    element: <Prestadores />,
  },
  {
    path: "/desempenho",
    element: <Dashboard />,
  },
  {
    path: "/vendedores/",
    element: <Vendedores />,
  },
  {
    path: "/cars/:placa",
    element: <Car />,
  },
  {
    path: "/cars/sold/:placa",
    element: <SoldCar />,
  },
  {
    path: "/tabela",
    element: <Tabela />,
  },
]);

ReactDOM.createRoot(document.getElementById("root") as HTMLElement).render(
  <React.StrictMode>
    <Provider store={store}>
      <ThemeProvider theme={theme}>
        <RouterProvider router={router} />
      </ThemeProvider>
    </Provider>
  </React.StrictMode>
);

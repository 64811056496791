import Typography from "@mui/material/Typography";
import { Alert, Box, Button, Card } from "@mui/material";
import {
  AttPreçosFipe,
  formatNumberToK,
  toReais,
} from "../../Services/AuxiliarFunctions";
import Avatar from "@mui/material/Avatar";
import { useEffect, useState } from "react";
import { Vehicle } from "../../types";
import AutorenewIcon from "@mui/icons-material/Autorenew";
import { LoadingButton } from "@mui/lab";
import { FipeOscilationChart } from "../../Components/Charts/FipeOscilationChart";

export default function MédiaFipe(props: {
  data: Vehicle[];
  fipePrice: number;
}) {
  const [value, setValue] = useState(0);
  const [tempoAnimacao] = useState(2000);
  const [loadingAttFipe, setLoadingAttFipe] = useState(false);
  const [statusAtt, setStatusAtt] = useState(false);

  const AttFipe = () => {
    setLoadingAttFipe(true);
    AttPreçosFipe(props.data).then((r) => {
      setLoadingAttFipe(false);
      setStatusAtt(true);
    });
  };

  useEffect(() => {
    const incremento = Math.ceil(props.fipePrice / (tempoAnimacao / 10));
    const intervalo = setInterval(() => {
      setValue((numeroAntigo) => {
        const diferenca = props.fipePrice - numeroAntigo;
        const porcentagem = diferenca / props.fipePrice;
        let novoIncremento = incremento;
        if (porcentagem <= 0.1) {
          novoIncremento = Math.ceil(incremento / 4);
        }
        if (numeroAntigo < props.fipePrice) {
          return numeroAntigo + novoIncremento;
        }
        clearInterval(intervalo);
        return props.fipePrice;
      });
    }, 1);
    return () => clearInterval(intervalo);
  }, [props]);
  return (
    <Card
      sx={{
        height: 150,
        p: 1,
        mb: 1,
      }}
    >
      <Box alignItems={"center"} display={"flex"} padding={1}>
        <Avatar
          sx={{ width: 70, height: 70, backgroundColor: "rgb(255, 255, 255)" }}
        >
          <img
            width={"80%"}
            src="https://lh3.googleusercontent.com/OpnLAJvzcWGGCr1BVWxzauu_mCRqvlxAQGUk91gDR3DH6YoHng8wcQWK234WkQprWEc"
          />
        </Avatar>
        <Box>
          <Typography fontFamily={"sans-serif"} color={"grey"} mx={2}>
            Ticket Médio
          </Typography>
          <Typography
            fontFamily={"monospace"}
            variant="h3"
            color={"black"}
            mx={2}
          >
            <strong>{formatNumberToK(value / props.data.length)}</strong>
          </Typography>
        </Box>
      </Box>
      <Box textAlign={"center"}>
        <LoadingButton
          onClick={AttFipe}
          style={{
            color: "#073250",
          }}
          loading={loadingAttFipe}
          startIcon={<AutorenewIcon />}
          loadingPosition="start"
          variant="text"
        >
          {loadingAttFipe ? "Atualizando Preços..." : "Atualizar Preços"}
        </LoadingButton>
      </Box>
      {statusAtt ? (
        <Alert severity="success">
          Atualizado com sucesso. É necessário reiniciar a aplicação.
        </Alert>
      ) : (
        ""
      )}
    </Card>
  );
}

import {
  ref,
  set,
  get,
  child,
  getDatabase,
  push,
  update,
  onValue,
  DataSnapshot,
  remove,
} from "firebase/database";
import { Despesa, Prestador, Vehicle, Venda, Vendedor } from "../types";
import { database } from "./Firebase";
const dbRef = ref(getDatabase());

const idloja = atob(localStorage.getItem("user")!);
console.log(idloja);

export function listenToUpdates(): Promise<Vehicle[]> {
  return new Promise((resolve, reject) => {
    onValue(ref(database, `${idloja}/vehicles`), (snapshot: DataSnapshot) => {
      if (snapshot.exists()) {
        const vehicles = snapshot.val() as { [key: string]: Vehicle };
        resolve(Object.values(vehicles));
      } else {
        reject(new Error("No data available"));
      }
    });
  });
}

export async function getVehicles() {
  try {
    const snapshot = await get(child(dbRef, `${idloja}/vehicles`));
    if (snapshot.exists()) {
      return snapshot.val();
    } else {
      console.log("No data available");
    }
  } catch (error) {
    console.error(error);
  }
}

export async function getVehicleByPlate(plate: string) {
  try {
    const snapshot = await get(child(dbRef, `${idloja}/vehicles`));
    if (snapshot.exists()) {
      const vehicles = snapshot.val();
      const vehiclesArray: Vehicle[] = Object.values(vehicles);
      const find = vehiclesArray.find((v) => v.placa === plate);
      if (!find) return undefined;
      return find;
    } else {
      console.log("No data available");
    }
  } catch (error) {
    console.error(error);
  }
}

export async function getSoldVehicleByPlate(plate: string) {
  try {
    const snapshot = await get(ref(database, `${idloja}/vendas`));
    const vendas = snapshot.val();
    const vendaID = Object.keys(vendas).find(
      (key) => vendas[key].vehicle.placa === plate
    );
    const snapshot2 = await get(
      ref(database, `${idloja}/vendas/${vendaID}/vehicle`)
    );
    if (snapshot2.exists()) {
      const vehicle = snapshot2.val();
      if (!vehicle) return undefined;
      return vehicle;
    } else {
      console.log("No data available");
    }
  } catch (error) {
    console.error(error);
  }
}

export async function addVehicle(vehicle: Vehicle) {
  const newPostKey = push(child(ref(database), `${idloja}/vehicles`)).key;
  const newVehicle = {
    ...vehicle,
    id: newPostKey,
  };
  const updates: { [key: string]: Vehicle } = {};
  updates[`${idloja}/vehicles/${newPostKey}`] = newVehicle as Vehicle;
  return update(ref(database), updates);
}

export async function updateVehicle(vehicle: Vehicle) {
  const updates: { [key: string]: Vehicle } = {};
  updates[`${idloja}/vehicles/${vehicle.id}`] = vehicle;
  return update(ref(database), updates);
}

export async function deleteVehicle(placa: string) {
  const snapshot = await get(ref(database, `${idloja}/vehicles`));
  const vehicles = snapshot.val();
  const vehicleId = Object.keys(vehicles).find(
    (key) => vehicles[key].placa === placa
  );
  const updates: { [key: string]: any } = {};
  updates[`${idloja}/vehicles/${vehicleId}`] = null;
  return update(ref(database), updates);
}

export async function addDespesa(despesa: Despesa, placa: string) {
  const snapshot = await get(ref(database, `${idloja}/vehicles`));
  const vehicles = snapshot.val();
  const vehicleId = Object.keys(vehicles).find(
    (key) => vehicles[key].placa === placa
  );
  const updates: { [key: string]: any } = {};
  const newPostKey = push(
    child(ref(database), `${idloja}/vehicles/${vehicleId}/despesas`)
  ).key;
  const newDespesa = {
    ...despesa,
    id: newPostKey,
  };
  updates[`${idloja}/vehicles/${vehicleId}/despesas/${newDespesa.id}`] =
    newDespesa;
  return update(ref(database), updates);
}

export async function addDespesaSold(despesa: Despesa, placa: string) {
  const snapshot = await get(ref(database, `${idloja}/vendas`));
  const vendas = snapshot.val();
  const vendaID = Object.keys(vendas).find(
    (key) => vendas[key].vehicle.placa === placa
  );
  const updates: { [key: string]: any } = {};
  const newPostKey = push(
    child(ref(database), `${idloja}/vendas/${vendaID}/vehicle/despesas`)
  ).key;
  const newDespesa = {
    ...despesa,
    id: newPostKey,
  };
  updates[`${idloja}/vendas/${vendaID}/vehicle/despesas/${newDespesa.id}`] =
    newDespesa;
  return update(ref(database), updates);
}

export async function deleteDespesa(despesaId: string, placa: string) {
  const snapshot = await get(ref(database, `${idloja}/vehicles`));
  const vehicles = snapshot.val();
  const vehicleId = Object.keys(vehicles).find(
    (key) => vehicles[key].placa === placa
  );
  const updates: { [key: string]: any } = {};
  updates[`${idloja}/vehicles/${vehicleId}/despesas/${despesaId}`] = null;
  return update(ref(database), updates);
}

export async function deleteDespesaSold(despesaId: string, placa: string) {
  const snapshot = await get(ref(database, `${idloja}/vendas`));
  const vendas = snapshot.val();
  const vendaID = Object.keys(vendas).find(
    (key) => vendas[key].vehicle.placa === placa
  );
  console.log(vendaID);

  const updates: { [key: string]: any } = {};
  updates[`${idloja}/vendas/${vendaID}/vehicle/despesas/${despesaId}`] = null;
  return update(ref(database), updates);
}

export async function addPrestador(prestador: Prestador) {
  const newPostKey = push(child(ref(database), `${idloja}/prestadores`)).key;
  const newPrestador = {
    ...prestador,
    id: newPostKey,
  };
  const updates: { [key: string]: Prestador } = {};
  updates[`${idloja}/prestadores/${newPostKey}`] = newPrestador as Prestador;
  return update(ref(database), updates);
}

export async function deletePrestador(nome: string) {
  const snapshot = await get(ref(database, `${idloja}/prestadores`));
  const prestador = snapshot.val();
  const prestadorId = Object.keys(prestador).find(
    (key) => prestador[key].nome === nome
  );
  const updates: { [key: string]: any } = {};
  updates[`${idloja}/prestadores/${prestadorId}`] = null;
  return update(ref(database), updates);
}

export async function getPrestadores() {
  try {
    const snapshot = await get(child(dbRef, `${idloja}/prestadores`));
    if (snapshot.exists()) {
      return snapshot.val();
    } else {
      console.log("No data available");
    }
  } catch (error) {
    console.error(error);
  }
}

export async function addVenda(venda: Venda, placa: string, car: Vehicle) {
  const newPostKey = push(child(ref(database), `${idloja}/vendas`)).key;
  const updates: { [key: string]: Venda } = {};
  const vendaJSON = {
    ...venda,
    vehicle: car,
  };
  updates[`${idloja}/vendas/${newPostKey}`] = vendaJSON;
  return update(ref(database), updates);
}

export async function getVendas() {
  try {
    const snapshot = await get(child(dbRef, `${idloja}/vendas`));
    if (snapshot.exists()) {
      return snapshot.val();
    }
  } catch (error) {
    console.error(error);
  }
}

export async function deleteVenda(comprador: string, data: string) {
  const snapshot = await get(ref(database, `${idloja}/vendas`));
  const venda = snapshot.val();
  const vendaID = Object.keys(venda).find(
    (key) => venda[key].comprador === comprador && venda[key].data === data
  );
  const updates: { [key: string]: any } = {};
  updates[`${idloja}/vendas/${vendaID}`] = null;
  return update(ref(database), updates);
}

export async function addVendedor(vendedor: Vendedor) {
  const newPostKey = push(child(ref(database), `${idloja}/vendedores`)).key;
  const updates: { [key: string]: Vendedor } = {};
  updates[`${idloja}/vendedores/${newPostKey}`] = vendedor as Vendedor;
  return update(ref(database), updates);
}

export async function getVendedores() {
  try {
    const snapshot = await get(child(dbRef, `${idloja}/vendedores`));
    if (snapshot.exists()) {
      return snapshot.val();
    }
  } catch (error) {
    console.error(error);
  }
}

export function listenToUpdatesVendedores(): Promise<Vendedor[]> {
  return new Promise((resolve, reject) => {
    onValue(ref(database, `${idloja}/vendedores`), (snapshot: DataSnapshot) => {
      if (snapshot.exists()) {
        const vend = snapshot.val() as { [key: string]: Vendedor };
        resolve(Object.values(vend));
      } else {
        reject(new Error("No data available"));
      }
    });
  });
}

export async function listenToUpdatesVendas(placa: string): Promise<Venda[]> {
  const snapshot = await get(ref(database, `${idloja}/vendas`));
  const vendas = snapshot.val();
  const vendaID = Object.keys(vendas).find(
    (key) => vendas[key].vehicle.placa === placa
  );
  return new Promise((resolve, reject) => {
    onValue(
      ref(database, `${idloja}/vendas/${vendaID}/vehicle/despesas`),
      (snapshot: DataSnapshot) => {
        if (snapshot.exists()) {
          const vend = snapshot.val() as { [key: string]: Venda };
          resolve(Object.values(vend));
        } else {
          reject(new Error("No data available"));
        }
      }
    );
  });
}

import React from 'react'

export default function Content(props : any) {

 return (

   <div className='content'>
  {props.children}
   </div>

)

}
import * as React from "react";
import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";
import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import Typography from "@mui/material/Typography";
import { LoadingButton } from "@mui/lab";
import SendIcon from "@mui/icons-material/Send";
import { uploadImages, uploadLogo } from "../Services/FirebaseStorage";
import { useState, useEffect } from "react";
import { AppDispatch } from "../Store";
import { useDispatch } from "react-redux";
import { updateUserConfig } from "../Store/Veículos";
import { useNavigate } from "react-router-dom";

const style = {
  position: "absolute" as "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 400,
  bgcolor: "background.paper",
  boxShadow: 24,
  pt: 2,
  px: 4,
  pb: 3,
};

interface FirtAccessModal {
  login: string;
}

export default function FirtAccessModal(props: FirtAccessModal) {
  const [color, setColor] = useState<string>();
  const [logo, setLogo] = useState<File[]>();

  const dispatch = useDispatch<AppDispatch>();
  const navigate = useNavigate();

  const handleFileUploadMiniatura = (e: any) => {
    const files = e.target.files;
    if (files) {
      let filesarray = [];
      for (let i = 0; i < files.length; i++) {
        const file = files[i];
        filesarray.push(file);
      }
      setLogo(filesarray);
    }
  };

  async function onCLickAtualizar() {
    let url: any;
    if (logo) {
      url = await uploadLogo(logo);
    }
    fetch(`https://api-cadastro-mgc.onrender.com/user/${props.login}`, {
      method: "PUT",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        logo: url ? url : "",
        color: color,
      }),
    })
      .then((response) => {
        dispatch(updateUserConfig({ color: color!, logo: url as string }));
        localStorage.setItem("logo", url);
        localStorage.setItem("color", color!);
        localStorage.setItem("user", btoa(props.login));
        navigate("/cars");
      })
      .catch((error) => {
        console.log(error);
      });
  }

  return (
    <div>
      <Modal
        open={props.login ? true : false}
        aria-labelledby="parent-modal-title"
        aria-describedby="parent-modal-description"
      >
        <Box sx={{ ...style, width: 800 }}>
          <Typography variant="h4" color="initial">
            Bem vindo ao MGC!
          </Typography>
          <Typography my={3} variant="h6" color="initial">
            Antes de continuar, precisamos fazer algumas configurações.
          </Typography>
          <TextField
            sx={{ width: "30%" }}
            type={"color"}
            onChange={(e) => setColor(e.target.value)}
            label="Cor Principal"
          />
          <TextField
            onChange={handleFileUploadMiniatura}
            sx={{ width: "30%", marginLeft: "5%" }}
            type={"file"}
            id=""
            label=""
          />
          <Typography my={3} variant="h6" color="initial">
            Selecione uma cor que combine com a loja. <br /> O logo deve ser em
            formato png, com fundo transparente para melhor experiência.
          </Typography>
          <LoadingButton
            disabled={color ? false : true}
            onClick={onCLickAtualizar}
            sx={{
              marginTop: "3%",
              left: "50%",
              transform: "translate(-50%, -50%)",
            }}
            size="large"
            color="primary"
            loadingPosition="start"
            endIcon={<SendIcon />}
            variant="contained"
          >
            Continuar
          </LoadingButton>
        </Box>
      </Modal>
    </div>
  );
}

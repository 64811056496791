import { configureStore } from "@reduxjs/toolkit";
import {
  Vehicles,
  PRestadores,
  Vendedores,
  Vendas,
  UserConfig,
} from "./Veículos";

export const store = configureStore({
  reducer: {
    vehicles: Vehicles,
    prestadores: PRestadores,
    vendedores: Vendedores,
    vendas: Vendas,
    UserConfig: UserConfig,
  },
});

export type RootState = ReturnType<typeof store.getState>;
// Inferred type: {posts: PostsState, comments: CommentsState, users: UsersState}
export type AppDispatch = typeof store.dispatch;

import { User } from "../types";

export async function verifyLogin(email: string, password: string) {
  try {
    const response = await fetch("https://api-cadastro-mgc.onrender.com/user");
    const data = await response.json();
    const find = data.find((e: User) => email === atob(e.key));
    console.log(find);

    if (!find) return "Usuário ou Senha incorreta";
    if (find.senha === password) {
      if (!find.color) return find.key;
      if (find.color) return { result: "login", user: find };
    }
    if (find.senhaFunc === password) {
      if (!find.color) return find.key;
      if (find.color) return { result: "loginFunc", user: find };
    } else return "Usuário ou Senha incorreta";
  } catch {
    return "Erro interno de servidor. Entre em contato com o suporte.";
  }
}

import { storage } from "./Firebase";
import { ref, uploadBytesResumable, getDownloadURL } from "firebase/storage";

function dataURLToBlob(dataURL: string) {
  const binary = atob(dataURL.split(",")[1]);
  const array = [];
  for (let i = 0; i < binary.length; i++) {
    array.push(binary.charCodeAt(i));
  }
  return new Blob([new Uint8Array(array)], { type: "image/webp" });
}

export async function uploadImages(files: Array<File>) {
  const promises = [];
  for (let index = 0; index < files.length; index++) {
    const canvas = document.createElement("canvas");
    const ctx = canvas.getContext("2d");
    const img = new Image();
    img.src = URL.createObjectURL(files[index]);
    promises.push(
      new Promise((resolve, reject) => {
        img.onload = () => {
          canvas.width = img.width;
          canvas.height = img.height;
          ctx?.drawImage(img, 0, 0);
          const dataURL = canvas.toDataURL("image/webp");
          const blob = dataURLToBlob(dataURL);
          const storageRef = ref(storage, `images/${files[index].name}.webp`);
          const uploadTask = uploadBytesResumable(storageRef, blob);
          uploadTask.on(
            "state_changed",
            (snapshot) => {
              const progress = "";
            },
            (error) => {
              reject(error);
            },
            () => {
              getDownloadURL(uploadTask.snapshot.ref).then((url) => {
                resolve(url);
              });
            }
          );
        };
      })
    );
  }
  return Promise.all(promises);
}

export async function uploadLogo(files: Array<File>) {
  const promises = [];
  for (let index = 0; index < files.length; index++) {
    const storageRef = ref(storage, `images/${files[index].name}`);
    const uploadTask = uploadBytesResumable(storageRef, files[index]);
    promises.push(
      new Promise((resolve, reject) => {
        uploadTask.on(
          "state_changed",
          (snapshot) => {
            const progress = "";
          },
          (error) => {
            reject(error);
          },
          () => {
            getDownloadURL(uploadTask.snapshot.ref).then((url) => {
              resolve(url);
            });
          }
        );
      })
    );
  }
  return Promise.all(promises);
}

import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { fetchVehicles, fetchFipe, UserConfig } from "../../Store/Veículos";
import type { RootState, AppDispatch } from "../../Store/index";
import { Box } from "@mui/material";
import TabelaPreços from "../../Components/TabelaPreços";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import dayjs from "dayjs";

export default function Tabela() {
  const dispatch = useDispatch<AppDispatch>();
  const vehicles = useSelector((state: RootState) => state.vehicles);
  const UserConfig = useSelector((state: RootState) => state.UserConfig);

  const [data, setData] = useState<Array<any>>([]);

  useEffect(() => {
    dispatch(fetchVehicles());
  }, [dispatch]);

  useEffect(() => {
    const newarray: Array<Object> = [];

    vehicles.data.forEach((vehicle) => {
      const vehiclesFilter = vehicles.data.filter(
        (v) => v.fipeInfo.brand === vehicle.fipeInfo.brand
      );
      const newObject = {
        [vehicle.fipeInfo.brand]: vehiclesFilter,
      };

      const hasObject = newarray.some((obj) => {
        return Object.keys(obj)[0] === vehicle.fipeInfo.brand;
      });

      if (!hasObject) {
        newarray.push(newObject);
      }
    });

    setData(newarray);
  }, [vehicles]);

  return (
    <>
      <div style={{ width: "100vw", height: "100%", backgroundColor: "white" }}>
        <Grid bgcolor={"white"} container p={5}>
          <Grid item xs={4}>
            <img style={{ width: "100%" }} src={UserConfig.logo || ""} />
          </Grid>
          <Grid item xs={8} textAlign={"center"}>
            <Typography py={2} variant="h2">
              Tabela de Preços
            </Typography>
            <Typography variant="body1">
              {dayjs(Date.now()).format("DD/MM/YYYY")}
            </Typography>
          </Grid>
        </Grid>
        <Box m={2}>
          {data.map((v) => (
            <TabelaPreços data={v} />
          ))}
        </Box>
      </div>
    </>
  );
}

import { Box, Grid, Skeleton } from "@mui/material";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import Appbar from "../../Components/Appbar";
import CadastroVendedor from "../../Components/CadastroVendedor";
import CardVendedor from "../../Components/Card/CardVendedor";
import { VendasChart } from "../../Components/Charts/VendasChart";
import VendedorChart from "../../Components/Charts/VendedorChart";
import Content from "../../Components/Content";
import ControllerBar from "../../Components/ControllerBar";
import { getVendas } from "../../Services/FirebaseDatabase";
import { AppDispatch, RootState } from "../../Store";
import {
  fetchPrestadores,
  fetchVehicles,
  fetchVendas,
  fetchVendedores,
} from "../../Store/Veículos";
import { Vehicle, Vendedor } from "../../types";

export default function Vendedores() {
  const navigate = useNavigate();
  const dispatch = useDispatch<AppDispatch>();
  const vendedoresSlice = useSelector(
    (state: RootState) => state.vendedores.data
  );

  const [vendedores, setVendedores] = useState<Vendedor[]>();
  const [dataFilter, setDataFilter] = useState("");

  function filterPerDate(value: string | null) {
    if (!value) return;
    setDataFilter(value);
  }

  function SearchChange(value: string) {
    if (value === "") {
      setVendedores(vendedoresSlice);
      return;
    }

    setVendedores(
      vendedores?.filter((vendedor) => {
        return vendedor.nome.toLowerCase().includes(value.toLowerCase());
      })
    );
  }

  function reloadData() {
    dispatch(fetchVendedores());
    setVendedores(vendedoresSlice);
  }

  useEffect(() => {
    reloadData();
  }, [dispatch]);

  useEffect(() => {
    setVendedores(vendedoresSlice);
  }, [vendedoresSlice]);

  useEffect(() => {
    if (!localStorage.getItem("user")) navigate("/");
    dispatch(fetchVehicles());
    dispatch(fetchVendas());
    dispatch(fetchPrestadores());
    dispatch(fetchVendedores());
  }, []);

  return (
    <>
      <Appbar />
      <Content>
        <ControllerBar
          component="vendedores"
          filterDate={filterPerDate}
          onChangeSearch={SearchChange}
        />
        <CadastroVendedor
          reload={reloadData}
          data={undefined}
          type={"cadastro"}
        />
        <Grid p={4} spacing={5} container>
          <Grid item xs={12} lg={6} height={500}>
            <Card sx={{ height: "100%" }}>
              <CardContent sx={{ height: "100%" }}>
                {vendedoresSlice?.length > 0 ? (
                  <VendedorChart filterDate={dataFilter} />
                ) : (
                  <Skeleton height={"100%"} width={"100%"} />
                )}
              </CardContent>
            </Card>
          </Grid>
          <Grid item xs={12} px={2} lg={6} justifyContent={"center"}>
            {vendedores ? (
              vendedores.map((vendedor) => (
                <CardVendedor filterDate={dataFilter} data={vendedor} />
              ))
            ) : (
              <Skeleton width={"100px"} />
            )}
          </Grid>
        </Grid>
      </Content>
    </>
  );
}

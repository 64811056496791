import * as React from "react";
import { styled, createTheme, ThemeProvider } from "@mui/material/styles";
import CssBaseline from "@mui/material/CssBaseline";
import MuiDrawer from "@mui/material/Drawer";
import Box from "@mui/material/Box";
import MuiAppBar, { AppBarProps as MuiAppBarProps } from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import List from "@mui/material/List";
import Divider from "@mui/material/Divider";
import IconButton from "@mui/material/IconButton";
import MenuIcon from "@mui/icons-material/Menu";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import DirectionsCarIcon from "@mui/icons-material/DirectionsCar";
import ShoppingCartIcon from "@mui/icons-material/ShoppingCart";
import PeopleIcon from "@mui/icons-material/People";
import BarChartIcon from "@mui/icons-material/BarChart";
import "@fontsource/roboto/300.css";
import { ListItemButton, ListItemIcon, ListItemText } from "@mui/material";
import { Link } from "react-router-dom";
import RequestQuoteIcon from "@mui/icons-material/RequestQuote";
import ConstructionRoundedIcon from "@mui/icons-material/ConstructionRounded";
import SupervisedUserCircleRoundedIcon from "@mui/icons-material/SupervisedUserCircleRounded";

const drawerWidth: number = 240;

interface AppBarProps extends MuiAppBarProps {
  open?: boolean;
}

const AppBar = styled(MuiAppBar, {
  shouldForwardProp: (prop) => prop !== "open",
})<AppBarProps>(({ theme, open }) => ({
  zIndex: theme.zIndex.drawer + 1,
  transition: theme.transitions.create(["width", "margin"], {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  ...(open && {
    marginLeft: drawerWidth,
    width: `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create(["width", "margin"], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  }),
}));

const Drawer = styled(MuiDrawer, {
  shouldForwardProp: (prop) => prop !== "open",
})(({ theme, open }) => ({
  "& .MuiDrawer-paper": {
    position: "relative",
    whiteSpace: "nowrap",
    width: drawerWidth,
    transition: theme.transitions.create("width", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
    boxSizing: "border-box",
    ...(!open && {
      overflowX: "hidden",
      transition: theme.transitions.create("width", {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
      }),
      [theme.breakpoints.down("sm")]: {
        width: 0,
      },
      [theme.breakpoints.up("sm")]: {
        width: theme.spacing(9),
      },
    }),
  },
}));

const mdTheme = createTheme({
  palette: {
    primary: {
      main: localStorage.getItem("color") || "#1a1a1a",
    },
    secondary: {
      main: "#878787",
    },
  },
});

function Appbar() {
  const [open, setOpen] = React.useState(false);
  const toggleDrawer = () => {
    setOpen(!open);
  };

  return (
    <ThemeProvider theme={mdTheme}>
      <Box sx={{ height: "100%", display: "flex" }}>
        <CssBaseline />
        <AppBar color="primary" position="absolute" open={open}>
          <Toolbar
            sx={{
              pr: "24px", // keep right padding when drawer closed
            }}
          >
            <IconButton
              edge="start"
              color="inherit"
              aria-label="open drawer"
              onClick={toggleDrawer}
              sx={{
                marginRight: "36px",
                ...(open && { display: "none" }),
              }}
            >
              <MenuIcon />
            </IconButton>
            <img
              style={{ height: "40px" }}
              src={localStorage.getItem("logo") || ""}
            />
          </Toolbar>
        </AppBar>

        <Drawer sx={{ height: "100%" }} variant="permanent" open={open}>
          <Toolbar
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "flex-end",
              px: [1],
            }}
          >
            <IconButton onClick={toggleDrawer}>
              <ChevronLeftIcon />
            </IconButton>
          </Toolbar>
          <Divider />

          <List
            sx={{
              height: "100%",
            }}
            component="nav"
          >
            <React.Fragment>
              <Link
                style={{ textDecoration: "none", color: "inherit" }}
                color="inherit"
                to={"/cars"}
              >
                <ListItemButton>
                  <ListItemIcon>
                    <DirectionsCarIcon color="secondary" />
                  </ListItemIcon>

                  <ListItemText color="inherit" primary="Veículos" />
                </ListItemButton>
              </Link>

              {localStorage.getItem("func") != "true" ? (
                <Link
                  style={{ textDecoration: "none", color: "inherit" }}
                  color="inherit"
                  to={"/vendas"}
                >
                  <ListItemButton>
                    <ListItemIcon>
                      <ShoppingCartIcon />
                    </ListItemIcon>
                    <ListItemText primary="Vendas" />
                  </ListItemButton>
                </Link>
              ) : (
                <></>
              )}

              <Link
                style={{ textDecoration: "none", color: "inherit" }}
                color="inherit"
                to={"/vendedores"}
              >
                <ListItemButton>
                  <ListItemIcon>
                    <SupervisedUserCircleRoundedIcon />
                  </ListItemIcon>
                  <ListItemText primary="Vendedores" />
                </ListItemButton>
              </Link>

              {localStorage.getItem("func") != "true" ? (
                <Link
                  style={{ textDecoration: "none", color: "inherit" }}
                  color="inherit"
                  to={"/prestadores"}
                >
                  <ListItemButton>
                    <ListItemIcon>
                      <ConstructionRoundedIcon />
                    </ListItemIcon>
                    <ListItemText primary="Prestadores" />
                  </ListItemButton>
                </Link>
              ) : (
                <></>
              )}

              {localStorage.getItem("func") != "true" ? (
                <Link
                  style={{ textDecoration: "none", color: "inherit" }}
                  color="inherit"
                  to={"/desempenho"}
                >
                  <ListItemButton>
                    <ListItemIcon>
                      <BarChartIcon />
                    </ListItemIcon>
                    <ListItemText primary="Desempenho" />
                  </ListItemButton>
                </Link>
              ) : (
                <></>
              )}

              <Link
                style={{ textDecoration: "none", color: "inherit" }}
                color="inherit"
                to={"/tabela"}
              >
                <ListItemButton>
                  <ListItemIcon>
                    <RequestQuoteIcon />
                  </ListItemIcon>
                  <ListItemText primary="Tabela de Preços" />
                </ListItemButton>
              </Link>
            </React.Fragment>
          </List>
        </Drawer>
      </Box>
    </ThemeProvider>
  );
}

export default Appbar;

import { Typography } from "@mui/material";
import dayjs from "dayjs";
import React, { PureComponent, useEffect, useState } from "react";
import { useSelector } from "react-redux";
import {
  AreaChart,
  Area,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  ResponsiveContainer,
} from "recharts";
import { toReais } from "../../Services/AuxiliarFunctions";
import { RootState } from "../../Store";
import { Venda } from "../../types";

interface DataObject {
  month: string;
  Vendas: number;
}
export function LucroMêsChart(props: { vendas: Venda[] }) {
  const [data, setData] = useState<DataObject[]>([]);
  const vendasSlice = props.vendas;

  const formatterHover = (value: string | number) => {
    if (Array.isArray(value)) {
      return value.map((v) => `R$ ${toReais(v)}`).join(", ");
    }
    return `${toReais(value)}`;
  };

  useEffect(() => {
    const mesAtual = dayjs(Date.now()).format("MMM, YYYY");
    const startDate = dayjs(mesAtual, "MMM YYYY");
    const sixMonthsAgo: string[] = [];
    for (let i = 0; i < 6; i++) {
      sixMonthsAgo.push(startDate.subtract(i, "month").format("MMM YYYY"));
    }
    const ArrayData: DataObject[] = [];
    sixMonthsAgo.forEach((month) => {
      const arr = vendasSlice.filter(
        (v) => dayjs(v.data).format("MMM YYYY") === month
      );
      console.log(month, arr);

      const soma = arr.reduce((acc, val) => (acc += val.lucro), 0);
      const obj = {
        month: month,
        Vendas: soma,
      };
      ArrayData.push(obj);
    });
    setData(ArrayData.reverse());
  }, []);

  return (
    <>
      <Typography textAlign={"center"} variant="h5" color="initial">
        Lucro Bruto por Mês
      </Typography>
      <ResponsiveContainer width="100%" height="100%">
        <AreaChart
          width={500}
          height={400}
          data={data}
          margin={{
            top: 10,
            right: 30,
            left: 40,
            bottom: 20,
          }}
        >
          <defs>
            <linearGradient id="green" x1="0" y1="0" x2="0" y2="1">
              <stop offset="50%" stopColor="#27ad6e" stopOpacity={1} />
              <stop offset="110%" stopColor="#0f7948" stopOpacity={0.8} />
            </linearGradient>
          </defs>
          <CartesianGrid strokeDasharray="3 3" />
          <XAxis dataKey="month" />
          <YAxis tickFormatter={(value) => `${toReais(value)}`} />
          <Tooltip formatter={(value) => formatterHover(value as number)} />
          <Area
            strokeWidth={5}
            type="monotone"
            dataKey="Vendas"
            stroke="#27ad6e"
            fill="url(#green)"
          />
        </AreaChart>
      </ResponsiveContainer>
    </>
  );
}

import Appbar from "../../Components/Appbar";
import Content from "../../Components/Content";
import ControllerBar from "../../Components/ControllerBar";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { fetchPrestadores } from "../../Store/Veículos";
import TablePrestadores from "../../Components/TablePrestadores";
import type { RootState, AppDispatch } from "../../Store/index";
import { Box } from "@mui/material";
import CadastroPrestadores from "../../Components/CadastroPrestador";
import CardPrestador from "../../Components/Card/CardPrestador";
import { Prestador } from "../../types";
import { useNavigate } from "react-router-dom";

export default function Prestadores() {
  const navigate = useNavigate();
  const prestadoresSlice = useSelector((state: RootState) => state.prestadores);
  const dispatch = useDispatch<AppDispatch>();
  const [cadastroState, setCadastroState] = useState<"settings" | "cadastro">(
    "cadastro"
  );
  const [editState, setEditState] = useState<{ nome: string; tipo: string }>();
  const [format, setFormat] = useState<"table" | "box">("table");
  const [dataFilter, setDataFilter] = useState("");
  const [sort, setSort] = useState<"valor" | "tipo" | "nome" | null>();
  const [prestadores, setPrestadores] = useState<Array<Prestador>>();

  function SearchChange(value: string) {
    if (value == "") {
      setPrestadores(prestadoresSlice.data);
      return;
    }
    setPrestadores(
      prestadoresSlice.data?.filter((p: Prestador) => {
        return p.nome.toLowerCase().includes(value.toLowerCase());
      })
    );
  }

  function changeCadastro(nome: string, tipo: string) {
    setCadastroState("settings");
    setEditState({ nome: nome, tipo: tipo });
  }

  function onChangeFormat(state: string) {
    if (state === "table") setFormat("table");
    if (state === "box") setFormat("box");
  }

  function filterPerDate(value: string | null) {
    if (!prestadores || !value) return;
    setDataFilter(value);
    setSort(null);
  }

  function sortChange(value: string | null) {
    if (value === "nome") setSort("nome");
    if (value === "tipo") setSort("tipo");
    if (value === "valor") setSort("valor");
  }

  useEffect(() => {
    if (!localStorage.getItem("user")) navigate("/");
    dispatch(fetchPrestadores());
    setPrestadores(prestadoresSlice.data);
  }, [dispatch]);

  useEffect(() => {
    setPrestadores(prestadoresSlice.data);
  }, [prestadoresSlice]);

  return (
    <>
      <Content>
        <CadastroPrestadores data={editState} type={cadastroState} />
        <ControllerBar
          sortChange={sortChange}
          filterDate={filterPerDate}
          onChangeFormat={onChangeFormat}
          component="prestadores"
          onChangeSearch={SearchChange}
        />

        {format === "box" ? (
          <Box
            display={"flex"}
            justifyContent={"center"}
            flexWrap={"wrap"}
            alignItems={"end"}
          >
            {prestadores ? (
              prestadores.map((p: any) => (
                <CardPrestador changeCadastro={changeCadastro} data={p} />
              ))
            ) : (
              <></>
            )}
          </Box>
        ) : (
          <></>
        )}
        {format == "table" ? (
          <TablePrestadores
            sort={sort ? sort : null}
            dataFilter={dataFilter}
            data={prestadores}
          />
        ) : (
          <></>
        )}
      </Content>
      <Appbar />
    </>
  );
}

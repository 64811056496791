import { Box } from "@mui/material";
import React, { useEffect, useState } from "react";
import { styled } from "@mui/material/styles";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell, { tableCellClasses } from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import TableFooter from "@mui/material/TableFooter";
import Paper from "@mui/material/Paper";
import { Prestador } from "../types";
import { toReais } from "../Services/AuxiliarFunctions";
import {
  getTotalDespesas,
  somarDespesasByPrestadorNameAndDate,
} from "../Services/CalcFunctions";
import TotalDespesasCell from "./TablesComplementaryComponents/TotalDespesasCell";

interface TablePrestadores {
  data: Prestador[] | undefined;
  dataFilter: string;
  sort: string | null;
}

export default function TablePrestadores(props: TablePrestadores) {
  const [somaDespesas, setSomaDespesas] = useState(0);
  const [somaPrestador, setSomaPrestador] = useState<{
    [nome: string]: number;
  }>({});
  const [prestadoresData, setPrestadoresData] = useState<Prestador[]>();
  const StyledTableCell = styled(TableCell)(({ theme }) => ({
    [`&.${tableCellClasses.head}`]: {
      backgroundColor: localStorage.getItem("color"),
      color: theme.palette.common.white,
    },
    [`&.${tableCellClasses.body}`]: {
      fontSize: 14,
    },
  }));

  const StyledTableRow = styled(TableRow)(({ theme }) => ({
    "&:nth-of-type(odd)": {
      backgroundColor: theme.palette.action.hover,
    },
    // hide last border
    "&:last-child td, &:last-child th": {
      border: 0,
    },
  }));

  useEffect(() => {
    if (props.data) {
      props.data.forEach((prestador) => {
        somarDespesasByPrestadorNameAndDate(
          prestador.nome,
          props.dataFilter
        ).then((soma) => {
          setSomaPrestador((prevSomas) => ({
            ...prevSomas,
            [prestador.nome]: soma,
          }));
        });
      });
      if (!props.dataFilter) {
        getTotalDespesas(props.data, undefined).then((total) =>
          setSomaDespesas(total)
        );
      }
      if (props.dataFilter) {
        getTotalDespesas(props.data, props.dataFilter).then((total) =>
          setSomaDespesas(total)
        );
      }
    }
  }, [props.data, props.dataFilter]);

  useEffect(() => {
    if (!props.data) return;
    function sortByName() {
      const arr = props.data!.slice();

      arr.sort((a, b) => {
        if (a.nome < b.nome) {
          return -1;
        } else if (a.nome > b.nome) {
          return 1;
        } else {
          return 0;
        }
      });
      return arr;
    }
    function sortByType() {
      const arr = props.data!.slice();

      arr.sort((a, b) => {
        if (a.tipo < b.tipo) {
          return -1;
        } else if (a.tipo > b.tipo) {
          return 1;
        } else {
          return 0;
        }
      });
      return arr;
    }
    function sortBySoma() {
      const arr = props.data!.slice();

      arr.sort((a, b) => {
        const aSoma = somaPrestador[a.nome];
        const bSoma = somaPrestador[b.nome];

        return bSoma - aSoma;
      });
      return arr;
    }
    let newArray: Prestador[] = [];
    if (props.sort) {
      if (props.sort === "nome") newArray = sortByName();
      if (props.sort === "tipo") newArray = sortByType();
      if (props.sort === "valor") newArray = sortBySoma();
      setPrestadoresData(newArray);
    } else {
      setPrestadoresData(props.data);
    }
  }, [props.data, props.dataFilter, props.sort]);

  const tableRows = prestadoresData?.map((prestador) => {
    const nome = prestador.nome;
    const soma = somaPrestador[nome];

    if (soma === 0) {
      return null;
    }

    return (
      <StyledTableRow>
        <StyledTableCell component="th" scope="row">
          {nome}
        </StyledTableCell>
        <StyledTableCell>{prestador.tipo}</StyledTableCell>
        <TotalDespesasCell dataFilter={props.dataFilter} nome={nome} />
      </StyledTableRow>
    );
  });

  return (
    <Box m={5} justifyContent={"center"}>
      <TableContainer
        sx={{
          marginBottom: "10%",
        }}
        component={Paper}
      >
        <Table aria-label="customized table">
          <TableHead>
            <TableRow>
              <StyledTableCell>Prestador</StyledTableCell>
              <StyledTableCell>Tipo</StyledTableCell>
              <StyledTableCell align="right">Total de Despesas</StyledTableCell>
            </TableRow>
          </TableHead>

          <TableBody>{tableRows}</TableBody>
          <TableFooter>
            <TableRow>
              <StyledTableCell />
              <StyledTableCell />
              <StyledTableCell
                sx={{
                  fontSize: "1rem",
                }}
                align="right"
              >
                {toReais(somaDespesas)}
              </StyledTableCell>
            </TableRow>
          </TableFooter>
        </Table>
      </TableContainer>
    </Box>
  );
}

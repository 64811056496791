import { Despesa, Vehicle, Venda } from "../types";
import { toNumber } from "./AuxiliarFunctions";
import { getVehicles, getVendas, listenToUpdates } from "./FirebaseDatabase";
import { Prestador } from "../types";
import dayjs from "dayjs";

export async function somarDespesasByPrestador(
  prestador: Prestador,
  dataFilter: string | undefined
) {
  let soma = 0;
  if (!dataFilter) {
    const vehicles = await listenToUpdates();
    vehicles.forEach((v: Vehicle) => {
      if (v.despesas) {
        const array = Object.values(v.despesas).filter(
          (obj) => obj.prestador === prestador.nome
        );
        array.forEach((d: Despesa) => {
          soma += Number(d.valor);
        });
      }
    });
  }
  if (dataFilter) {
    const vehicles = await listenToUpdates();
    vehicles.forEach((v: Vehicle) => {
      if (v.despesas) {
        const array = Object.values(v.despesas).filter(
          (obj) => obj.prestador === prestador.nome
        );
        array.forEach((d: Despesa) => {
          const data = dayjs(d.data);
          const mesEAnoFormatado = data.format("MMMM YYYY");
          if (mesEAnoFormatado === dataFilter) {
            soma += Number(d.valor);
          }
        });
      }
    });
  }

  return soma;
}

export async function somarDespesasByPrestadorNameAndDate(
  nome: string,
  dataFilter: string
) {
  let soma = 0;
  if (!dataFilter) {
    const vehicles = await listenToUpdates();
    vehicles.forEach((v: Vehicle) => {
      if (v.despesas) {
        const array = Object.values(v.despesas).filter(
          (obj) => obj.prestador === nome
        );
        array.forEach((d: Despesa) => {
          soma += Number(d.valor);
        });
      }
    });
  }
  if (dataFilter) {
    const vehicles = await listenToUpdates();
    vehicles.forEach((v: Vehicle) => {
      if (v.despesas) {
        const array = Object.values(v.despesas).filter(
          (obj) => obj.prestador === nome
        );
        array.forEach((d: Despesa) => {
          const data = dayjs(d.data);
          const mesEAnoFormatado = data.format("MMMM YYYY");
          if (mesEAnoFormatado === dataFilter) {
            soma += Number(d.valor);
          }
        });
      }
    });
  }

  return soma;
}

export async function getTotalDespesas(
  p: Prestador[],
  data: string | undefined
): Promise<number> {
  const promises: Array<Promise<number>> = [];
  let soma = 0;
  if (!data) {
    p.forEach((prestador) => {
      promises.push(somarDespesasByPrestador(prestador, undefined));
    });
    const results = await Promise.all(promises);

    results.forEach((result) => {
      soma += result;
    });
  }
  if (data) {
    p.forEach((prestador) => {
      promises.push(somarDespesasByPrestador(prestador, data));
    });
    const results = await Promise.all(promises);

    results.forEach((result) => {
      soma += result;
    });
  }

  return soma;
}

export async function getTotalFipePrice() {
  let soma = 0;
  const vehicles: Vehicle[] = await getVehicles();
  Object.values(vehicles).forEach((vehicle) => {
    soma += toNumber(vehicle.fipeInfo.price);
  });
  return soma;
}

export function somarNumeroTotalDespesas(mes: string, vendas: Venda[]) {
  const filtro = vendas.filter(
    (venda) => dayjs(venda.data).format("MMMM YY") === mes
  );
  return filtro.length;
}

import { Checkbox, FormControlLabel } from "@mui/material";
import react, { useEffect, useState } from "react";

interface CheckboxOpcionaisProps {
  selecteds: string[];
}

interface CheckboxState {
  name: string;
  defaultChecked: boolean;
}

export default function CheckboxOpcionais(props: CheckboxOpcionaisProps) {
  const [items, setItems] = useState<string[]>();
  useEffect(() => {
    setItems(props.selecteds);
  }, [props.selecteds]);
  if (!items) return <></>;
  return (
    <table>
      <tr>
        <td style={{ textAlign: "left" }}>
          <FormControlLabel
            control={
              <Checkbox defaultChecked={items.some((s) => s === "Air Bag")} />
            }
            label="Air Bag"
          />
        </td>
        <td style={{ textAlign: "left" }}>
          <FormControlLabel
            control={
              <Checkbox
                defaultChecked={items.some((s) => s === "Air Bag Duplo")}
              />
            }
            label="Air Bag Duplo"
          />
        </td>
        <td style={{ textAlign: "left" }}>
          <FormControlLabel
            control={
              <Checkbox
                defaultChecked={items.some((s) => s === "Air Bags Laterais")}
              />
            }
            label="Air Bags Laterais"
          />
        </td>
        <td style={{ textAlign: "left" }}>
          <FormControlLabel
            control={
              <Checkbox
                defaultChecked={items.some((s) => s === "Freios ABS")}
              />
            }
            label="Freios ABS"
          />
        </td>
        <td style={{ textAlign: "left" }}>
          <FormControlLabel
            control={
              <Checkbox
                defaultChecked={items.some((s) => s === "Faróis de neblina")}
              />
            }
            label="Faróis de neblina"
          />
        </td>
      </tr>

      <tr>
        <td style={{ textAlign: "left" }}>
          <FormControlLabel
            control={
              <Checkbox
                defaultChecked={items.some((s) => s === "Computador de bordo")}
              />
            }
            label="Computador de bordo"
          />
        </td>
        <td style={{ textAlign: "left" }}>
          <FormControlLabel
            control={
              <Checkbox
                defaultChecked={items.some((s) => s === "Vidros Elétricos")}
              />
            }
            label="Vidros Elétricos"
          />
        </td>
        <td style={{ textAlign: "left" }}>
          <FormControlLabel
            control={
              <Checkbox
                defaultChecked={items.some(
                  (s) => s === "Retrovisores elétricos"
                )}
              />
            }
            label="Retrovisores elétricos"
          />
        </td>
        <td style={{ textAlign: "left" }}>
          <FormControlLabel
            control={
              <Checkbox
                defaultChecked={items.some(
                  (s) => s === "Trava elétrica das portas"
                )}
              />
            }
            label="Trava elétrica das portas"
          />
        </td>
        <td style={{ textAlign: "left" }}>
          <FormControlLabel
            control={
              <Checkbox
                defaultChecked={items.some(
                  (s) => s === "Volante com ajuste de altura"
                )}
              />
            }
            label="Volante com ajuste de altura"
          />
        </td>
      </tr>

      <tr>
        <td style={{ textAlign: "left" }}>
          <FormControlLabel
            control={
              <Checkbox defaultChecked={items.some((s) => s === "Alarme")} />
            }
            label="Alarme"
          />
        </td>
        <td style={{ textAlign: "left" }}>
          <FormControlLabel
            control={
              <Checkbox
                defaultChecked={items.some((s) => s === "Direção hidráulica")}
              />
            }
            label="Direção hidráulica"
          />
        </td>
        <td style={{ textAlign: "left" }}>
          <FormControlLabel
            control={
              <Checkbox
                defaultChecked={items.some((s) => s === "Direção elétrica")}
              />
            }
            label="Direção elétrica"
          />
        </td>
        <td style={{ textAlign: "left" }}>
          <FormControlLabel
            control={
              <Checkbox
                defaultChecked={items.some((s) => s === "Câmbio automático")}
              />
            }
            label="Câmbio automático"
          />
        </td>
        <td style={{ textAlign: "left" }}>
          <FormControlLabel
            control={
              <Checkbox
                defaultChecked={items.some(
                  (s) => s === "Banco do motorista com ajuste de altura"
                )}
              />
            }
            label="Banco do motorista com ajuste de altura"
          />
        </td>
      </tr>

      <tr>
        <td style={{ textAlign: "left" }}>
          <FormControlLabel
            control={
              <Checkbox
                defaultChecked={items.some((s) => s === "Limpador traseiro")}
              />
            }
            label="Limpador traseiro"
          />
        </td>
        <td style={{ textAlign: "left" }}>
          <FormControlLabel
            control={
              <Checkbox
                defaultChecked={items.some(
                  (s) => s === "Desembaçador traseiro"
                )}
              />
            }
            label="Desembaçador traseiro"
          />
        </td>
        <td style={{ textAlign: "left" }}>
          <FormControlLabel
            control={
              <Checkbox
                defaultChecked={items.some((s) => s === "Ar Condicionado")}
              />
            }
            label="Ar Condicionado"
          />
        </td>
        <td style={{ textAlign: "left" }}>
          <FormControlLabel
            control={
              <Checkbox defaultChecked={items.some((s) => s === "Ar Quente")} />
            }
            label="Ar Quente"
          />
        </td>
        <td style={{ textAlign: "left" }}>
          <FormControlLabel
            control={
              <Checkbox
                defaultChecked={items.some((s) => s === "Porta malas elétrico")}
              />
            }
            label="Porta malas elétrico"
          />
        </td>
      </tr>

      <tr>
        <td style={{ textAlign: "left" }}>
          <FormControlLabel
            control={
              <Checkbox defaultChecked={items.some((s) => s === "CD player")} />
            }
            label="CD player"
          />
        </td>
        <td style={{ textAlign: "left" }}>
          <FormControlLabel
            control={
              <Checkbox
                defaultChecked={items.some((s) => s === "Bancos em couro")}
              />
            }
            label="Bancos em couro"
          />
        </td>
        <td style={{ textAlign: "left" }}>
          <FormControlLabel
            control={
              <Checkbox
                defaultChecked={items.some((s) => s === "Tração 4x4")}
              />
            }
            label="Tração 4x4"
          />
        </td>
        <td style={{ textAlign: "left" }}>
          <FormControlLabel
            control={
              <Checkbox
                defaultChecked={items.some((s) => s === "Rodas de liga leve")}
              />
            }
            label="Rodas de liga leve"
          />
        </td>
        <td style={{ textAlign: "left" }}>
          <FormControlLabel
            control={
              <Checkbox
                defaultChecked={items.some(
                  (s) => s === "Volante multifuncional"
                )}
              />
            }
            label="Volante multifuncional"
          />
        </td>
      </tr>

      <tr>
        <td style={{ textAlign: "left" }}>
          <FormControlLabel
            control={
              <Checkbox
                defaultChecked={items.some(
                  (s) => s === "Sensor de estacionamento"
                )}
              />
            }
            label="Sensor de estacionamento"
          />
        </td>
        <td style={{ textAlign: "left" }}>
          <FormControlLabel
            control={
              <Checkbox
                defaultChecked={items.some((s) => s === "Piloto automático")}
              />
            }
            label="Piloto automático"
          />
        </td>
        <td style={{ textAlign: "left" }}>
          <FormControlLabel
            control={
              <Checkbox
                defaultChecked={items.some((s) => s === "Câmera de Ré")}
              />
            }
            label="Câmera de Ré"
          />
        </td>
        <td style={{ textAlign: "left" }}>
          <FormControlLabel
            control={
              <Checkbox
                defaultChecked={items.some((s) => s === "Teto solar")}
              />
            }
            label="Teto solar"
          />
        </td>
        <td style={{ textAlign: "left" }}>
          <FormControlLabel
            control={
              <Checkbox
                defaultChecked={items.some(
                  (s) => s === "Controle de som no volante"
                )}
              />
            }
            label="Controle de som no volante"
          />
        </td>
      </tr>

      <tr>
        <td style={{ textAlign: "left" }}>
          <FormControlLabel
            control={
              <Checkbox
                defaultChecked={items.some(
                  (s) => s === "Kit gás natural veicular"
                )}
              />
            }
            label="Kit gás natural veicular"
          />
        </td>
        <td style={{ textAlign: "left" }}>
          <FormControlLabel
            control={
              <Checkbox defaultChecked={items.some((s) => s === "Blindado")} />
            }
            label="Blindado"
          />
        </td>
        <td style={{ textAlign: "left" }}>
          <FormControlLabel
            control={
              <Checkbox defaultChecked={items.some((s) => s === "0 KM")} />
            }
            label="0 KM"
          />
        </td>
        <td style={{ textAlign: "left" }}>
          <FormControlLabel
            control={
              <Checkbox
                defaultChecked={items.some((s) => s === "Único dono")}
              />
            }
            label="Único dono"
          />
        </td>
      </tr>
    </table>
  );
}

import axios from "axios";

export async function getModels(brandId: string) {
  const response = await axios.get(
    `https://parallelum.com.br/fipe/api/v2/cars/brands/${brandId}/models`
  );
  return response;
}

export async function getYears(brandId: string, modelId: string) {
  const response = await axios.get(
    `https://parallelum.com.br/fipe/api/v2/cars/brands/${brandId}/models/${modelId}/years`
  );
  return response;
}

export async function getFipeInfo(
  brandId: string,
  modelId: string,
  yearId: string
) {
  const response = await axios.get(
    `https://parallelum.com.br/fipe/api/v2/cars/brands/${brandId}/models/${modelId}/years/${yearId}`
  );
  return response;
}

export async function getFipeInfoByCode(code: string, yearId: string) {
  const response = await axios.get(
    `https://parallelum.com.br/fipe/api/v2/cars/${code}/years/${yearId}`
  );
  return response;
}

export async function getPriceHistory(fipeCode: string, yearId: string) {
  const response = await axios.get(
    `https://parallelum.com.br/fipe/api/v2/cars/${fipeCode}/years/${yearId}/history`
  );
  return response.data.priceHistory;
}

import * as React from "react";
import { useState, useEffect, useRef } from "react";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import Typography from "@mui/material/Typography";
import { Box, Grid, TextField, MenuItem, Alert } from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import Card from "@mui/material/Card";
import SaveIcon from "@mui/icons-material/Save";
import LoadingButton from "@mui/lab/LoadingButton";
import { uploadImages } from "../Services/FirebaseStorage";
import { addVehicle } from "../Services/FirebaseDatabase";
import { Vehicle } from "../types";
import { useSelector } from "react-redux";
import { fetchVehicles, selector } from "../Store/Veículos";
import {
  getFipeInfo,
  getModels,
  getPriceHistory,
  getYears,
} from "../Services/Fipe";
import accounting, { formatMoney } from "accounting";
import { useDispatch } from "react-redux";
import { AppDispatch } from "../Store";
import FormGroup from "@mui/material/FormGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";
import CheckboxOpcionais from "./CheckboxOpcionais";

const formInitialState = {
  id: "",
  modelo: "",
  marca: "",
  chassi: "",
  ano: "",
  placa: "",
  cor: "",
  renavam: "",
  fotos: [],
  valorCompra: "",
  dataCompra: Date.now(),
  valorVenda: "",
  despesas: [],
  fipeInfo: {
    price: "",
    brand: "",
    model: "",
    modelYear: 0,
    fuel: "",
    codeFipe: "",
    referenceMonth: "",
    vehicleType: 1,
    fuelAcronym: "",
  },
  priceHistory: [],
  km: "",
};

accounting.settings = {
  currency: {
    symbol: "",
    format: "%v %s",
    decimal: ",",
    thousand: ".",
    precision: 2,
  },
  number: {
    precision: 0,
    thousand: ".",
    decimal: ",",
  },
};

export default function Cadastro() {
  const [opcionais, setopcionais] = useState<string[]>([]);
  const dispatch = useDispatch<AppDispatch>();
  const inputValorCompra = useRef<any>(null);
  const inputModelo = useRef<any>(null);
  const inputAno = useRef<any>(null);
  const inputFipeValue = useRef<any>(null);
  const inputFotos = useRef<any>(null);
  const inputFotosMiniatura = useRef<any>(null);

  const [anos, setAnos] = useState<any>([]);
  const marcas = useSelector(selector).fipe.marcas;
  const [modelos, setModelos] = useState<Array<any>>([]);
  const [files, setFiles] = useState<File[]>([]);
  const [miniatura, setMiniatura] = useState<File[]>();
  const [images, setImages] = useState<Array<string>>([]);
  const [imageMiniatura, setImageMiniatura] = useState<string[]>();
  const [form, setForm] = useState<Vehicle>(formInitialState);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState("");
  const [success, setSuccess] = useState("");

  function getSelectedCheckboxes(): string[] {
    const checkboxes = document.querySelectorAll<HTMLInputElement>(
      'input[type="checkbox"]'
    );
    const selectedCheckboxes: string[] = [];

    checkboxes.forEach((checkbox) => {
      if (checkbox.checked) {
        selectedCheckboxes.push(checkbox.labels![0].textContent ?? "");
      }
    });
    console.log(selectedCheckboxes);

    return selectedCheckboxes;
  }

  const onInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const id = e.target.id;
    setForm({
      ...form,
      [id]: e.target.value,
    });
  };

  function onSelectChange(id: string, value: string) {
    setForm({
      ...form,
      [id]: value,
    });
  }

  const onBlurValorCompra = (e: React.FocusEvent<HTMLInputElement>) => {
    const value = "R$ " + formatMoney(e.target.value);
    setForm({
      ...form,
      valorCompra: value,
    });
  };

  const handleFileUpload = (e: any) => {
    const files = e.target.files;
    if (files) {
      let urlimages = [];
      let filesarray = [];

      for (let i = 0; i < files.length; i++) {
        const file = files[i];
        const url = URL.createObjectURL(file);
        urlimages.push(url);
        filesarray.push(file);
      }
      setImages(urlimages);
      setFiles(filesarray);
    }
  };

  const handleFileUploadMiniatura = (e: any) => {
    const files = e.target.files;
    if (files) {
      let urlimages = [];
      let filesarray = [];

      for (let i = 0; i < files.length; i++) {
        const file = files[i];
        const url = URL.createObjectURL(file);
        urlimages.push(url);
        filesarray.push(file);
      }
      setImageMiniatura(urlimages);
      setMiniatura(filesarray);
    }
  };

  async function onClickCadastro() {
    if (
      !form.marca ||
      !form.modelo ||
      !form.ano ||
      !form.dataCompra ||
      !form.placa ||
      !form.cor ||
      !form.valorCompra ||
      !form.dataCompra ||
      !miniatura
    ) {
      setSuccess("");
      setError("é necessário preencher todos os campos.");
      return;
    }
    setLoading(true);
    setError("");

    const arrayFiles = files;
    arrayFiles.unshift(miniatura[0]);
    const urls: any = await uploadImages(arrayFiles);
    const opcionais = getSelectedCheckboxes();
    const formUrls = {
      ...form,
      fotos: urls,
      opcionais: opcionais,
    };
    addVehicle(formUrls)
      .then((response) => {
        setLoading(false);
        setForm(formInitialState);
        setImages([]);
        setSuccess("Veículo cadastrado com sucesso.");
        dispatch(fetchVehicles());
        setAnos([]);
        setModelos([]);
      })
      .catch((err) => {
        setSuccess("");
        setError(err.message);
      });
  }

  useEffect(() => {
    if (form.marca != "") {
      const getAnos = async () => {
        const years = await getYears(form.marca, form.modelo);
        setAnos(years.data);
      };

      const getModelsAsync = async () => {
        const marcas = await getModels(form.marca);
        setModelos(marcas.data);
        getAnos();
      };
      getModelsAsync();
    }
  }, [form, dispatch]);

  useEffect(() => {
    if (form.marca != "" && form.modelo != "" && form.ano != "") {
      const getInfos = async () => {
        const infos = await getFipeInfo(form.marca, form.modelo, form.ano);
        setForm({
          ...form,
          fipeInfo: infos.data,
        });
        const priceHistory = await getPriceHistory(
          form.fipeInfo.codeFipe,
          form.ano
        );
        setForm({
          ...form,
          fipeInfo: infos.data,
          priceHistory: priceHistory,
        });
      };

      getInfos();
    }
  }, [form.ano, form.modelo]);

  useEffect(() => {
    setAnos([]);
    inputAno.current.value = "";
    inputModelo.current.value = "";
    inputFipeValue.current.value = "";
    setForm({
      ...form,
      ano: "",
      fipeInfo: {
        ...form.fipeInfo,
        price: "",
      },
    });
  }, [form.marca]);

  useEffect(() => {
    inputAno.current.value = "";
    inputFipeValue.current.value = "";
    setAnos([]);
    setForm({
      ...form,
      ano: "",
    });
  }, [form.modelo]);

  return (
    <div>
      <Accordion
        sx={{
          display: { xs: "none", lg: "block" },
        }}
      >
        <AccordionSummary
          aria-controls="panel1a-content"
          id="panel1a-header"
          expandIcon={<ExpandMoreIcon />}
        >
          <Typography>Cadastro</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <form>
            <Grid container>
              <Grid item xs={12} md={5}>
                <Grid
                  padding={"2%"}
                  container
                  justifyContent={"center"}
                  textAlign={"center"}
                >
                  <Grid item xs={10}>
                    <Box
                      component="span"
                      sx={{
                        display: "inline-block",
                        width: "80%",
                        aspectRatio: "1",
                      }}
                    >
                      <Card
                        sx={{
                          border:
                            images.length == 0 ? "1 px solid grey" : "none",
                          height: "100%",
                        }}
                        variant="outlined"
                      >
                        <img
                          style={{
                            width: "100%",
                          }}
                          src={imageMiniatura ? imageMiniatura[0] : ""}
                          alt=""
                        />
                      </Card>
                    </Box>
                  </Grid>

                  <Grid item xs={2}>
                    <Grid container flexDirection={"column"}></Grid>
                    <Card
                      sx={{
                        border: images.length < 1 ? "1 px solid grey" : "none",
                        marginBottom: "12%",
                        width: "90%",
                        aspectRatio: "1",
                        marginLeft: "-30%",
                      }}
                      variant="outlined"
                    >
                      <img
                        style={{
                          width: "100%",
                        }}
                        src={images[0]}
                        alt=""
                      />
                    </Card>
                    <Card
                      sx={{
                        border: images.length < 2 ? "1 px solid grey" : "none",
                        marginBottom: "12%",
                        width: "90%",
                        aspectRatio: "1",
                        marginLeft: "-30%",
                      }}
                      variant="outlined"
                    >
                      <img
                        style={{
                          width: "100%",
                        }}
                        src={images[1]}
                        alt=""
                      />
                    </Card>
                    <Card
                      sx={{
                        border: images.length <= 3 ? "1 px solid grey" : "none",
                        marginBottom: "12%",
                        width: "90%",
                        aspectRatio: "1",
                        marginLeft: "-30%",
                      }}
                      variant="outlined"
                    >
                      <img
                        style={{
                          width: "100%",
                        }}
                        src={images[2]}
                        alt=""
                      />
                    </Card>
                    <Card
                      sx={{
                        border: images.length <= 4 ? "1 px solid grey" : "none",
                        marginBottom: "12%",
                        width: "90%",
                        aspectRatio: "1",
                        marginLeft: "-30%",
                      }}
                      variant="outlined"
                    >
                      {images.length == 4 ? (
                        <img
                          style={{
                            width: "100%",
                          }}
                          src={images[3]}
                          alt=""
                        />
                      ) : (
                        ""
                      )}
                      {images.length >= 5 ? `+${images.length - 4}` : ""}
                    </Card>
                  </Grid>

                  <TextField
                    ref={inputFotosMiniatura}
                    onChange={handleFileUploadMiniatura}
                    sx={{
                      marginTop: "2%",
                      marginLeft: "5%",
                      width: "90%",
                    }}
                    type={"file"}
                  />

                  <TextField
                    ref={inputFotos}
                    onChange={handleFileUpload}
                    inputProps={{ multiple: true }}
                    sx={{
                      marginTop: "2%",
                      marginLeft: "5%",
                      width: "90%",
                    }}
                    type={"file"}
                  />
                </Grid>
              </Grid>

              <Grid textAlign={"center"} item xs={12} md={7}>
                <Box
                  component="form"
                  noValidate
                  autoComplete="off"
                  sx={{
                    "& .MuiTextField-root": { m: 1, my: 2 },
                  }}
                >
                  <TextField
                    sx={{
                      width: "25%",
                    }}
                    required
                    onChange={(event) =>
                      onSelectChange("marca", event.target.value)
                    }
                    select
                    id="marca"
                    label="Marca"
                  >
                    {marcas.map((option: any) => (
                      <MenuItem key={option.name} value={option.code}>
                        {option.name}
                      </MenuItem>
                    ))}
                  </TextField>

                  <TextField
                    ref={inputModelo}
                    sx={{
                      width: "65%",
                    }}
                    required
                    onChange={(event) =>
                      onSelectChange("modelo", event.target.value)
                    }
                    select
                    id="modelo"
                    label="Modelo"
                  >
                    {modelos.map((option: any) => (
                      <MenuItem key={option.name} value={option.code}>
                        {option.name}
                      </MenuItem>
                    ))}
                  </TextField>

                  <TextField
                    ref={inputAno}
                    sx={{
                      width: "29%",
                    }}
                    required
                    onChange={(event) =>
                      onSelectChange("ano", event.target.value)
                    }
                    select
                    id="ano"
                    label="Ano"
                  >
                    {anos.map((option: any) => (
                      <MenuItem key={option.name} value={option.code}>
                        {option.name}
                      </MenuItem>
                    ))}
                  </TextField>

                  <TextField
                    sx={{
                      width: "29.25%",
                    }}
                    value={form.placa}
                    onChange={onInputChange}
                    id="placa"
                    label="Placa"
                  />
                  <TextField
                    sx={{
                      width: "29.25%",
                    }}
                    required
                    value={form.cor}
                    onChange={onInputChange}
                    id="cor"
                    label="Cor"
                  />
                  <TextField
                    sx={{
                      width: "45%",
                    }}
                    id="renavam"
                    value={form.renavam}
                    onChange={onInputChange}
                    label="Renavam"
                  />
                  <TextField
                    ref={inputValorCompra}
                    sx={{ width: "45%" }}
                    required
                    type="text"
                    onBlur={onBlurValorCompra}
                    onChange={onInputChange}
                    id="valorCompra"
                    label="Valor da Compra"
                    value={form.valorCompra}
                  />
                  <TextField
                    id="km"
                    onChange={onInputChange}
                    label={"Quilometragem"}
                    sx={{
                      width: "92.5%",
                    }}
                  />
                  <TextField
                    sx={{
                      width: "35%",
                    }}
                    onChange={onInputChange}
                    type="date"
                    value={form.dataCompra}
                    id="dataCompra"
                  />
                  <TextField
                    ref={inputFipeValue}
                    sx={{
                      width: "55%",
                      color: "black",
                    }}
                    InputProps={{
                      readOnly: true,
                    }}
                    onChange={onInputChange}
                    value={form.fipeInfo.price ? form.fipeInfo.price : "Fipe"}
                    helperText={
                      "preço de referencia: " + form.fipeInfo.referenceMonth
                    }
                  />
                </Box>
              </Grid>

              <Grid
                display={"flex"}
                flexWrap={"wrap"}
                justifyContent={"center"}
                item
                xs={12}
              >
                <Box
                  display={"flex"}
                  justifyContent={"center"}
                  width={"100%"}
                  textAlign={"center"}
                >
                  <CheckboxOpcionais selecteds={[]} />
                </Box>

                <LoadingButton
                  sx={{
                    marginTop: "3%",
                  }}
                  onClick={onClickCadastro}
                  size="small"
                  color="primary"
                  loading={loading}
                  loadingPosition="start"
                  startIcon={<SaveIcon />}
                  variant="contained"
                >
                  <span>Cadastrar</span>
                </LoadingButton>

                {success ? <Alert severity="success">{success}</Alert> : <></>}
                {error ? <Alert severity="error">{error}</Alert> : <></>}
              </Grid>
            </Grid>
          </form>
        </AccordionDetails>
      </Accordion>
    </div>
  );
}

import Typography from "@mui/material/Typography";
import { Box, Icon } from "@mui/material";
import Avatar from "@mui/material/Avatar";
import { useEffect, useState } from "react";
import TimeToLeaveIcon from "@mui/icons-material/TimeToLeave";
import { useSelector } from "react-redux";
import { RootState } from "../../Store";
import { Vehicle } from "../../types";

export default function CarrosEmEstoque(props: { vehicles?: Vehicle[] }) {
  const vehicles = props.vehicles || [];
  const [vehiclesNumber, setVehiclesNumber] = useState(0);
  const [tempoAnimacao] = useState(5000);

  useEffect(() => {
    const incremento = Math.ceil(vehicles.length / (tempoAnimacao / 10));
    const intervalo = setInterval(() => {
      setVehiclesNumber((numeroAntigo) => {
        const diferenca = vehicles.length - numeroAntigo;
        const porcentagem = diferenca / vehicles.length;
        let novoIncremento = incremento;
        if (porcentagem <= 0.2) {
          novoIncremento = Math.ceil(incremento / 2);
        }
        if (numeroAntigo < vehicles.length) {
          return numeroAntigo + novoIncremento;
        }
        clearInterval(intervalo);
        return vehicles.length;
      });
    }, 30);
    return () => clearInterval(intervalo);
  }, [props]);
  return (
    <>
      <Box alignItems={"center"} display={"flex"} padding={1}>
        <Avatar
          sx={{ width: 70, height: 70, backgroundColor: "rgb(224, 64, 97)" }}
        >
          <TimeToLeaveIcon fontSize="large" />
        </Avatar>

        <Box>
          <Typography fontFamily={"sans-serif"} color={"grey"} mx={2}>
            Veículos em Estoque
          </Typography>
          <Typography
            fontFamily={"monospace"}
            variant="h2"
            color={"black"}
            mx={2}
          >
            <strong>{vehiclesNumber}</strong>
          </Typography>
        </Box>
      </Box>
    </>
  );
}

import { Box, Grid, IconButton, TextField } from "@mui/material";
import { useState, useEffect } from "react";
import SendIcon from "@mui/icons-material/Send";
interface User {
  email: string;
  senha: string;
  id: string;
  color: string;
  logo: string;
}
export default function AddUserAdmnin() {
  const [form, setForm] = useState<User>({} as User);
  const [logo, setLogo] = useState<File[]>();

  const onChangeForm = (e: React.ChangeEvent<HTMLInputElement>) => {
    const id = e.target.id;
    setForm({
      ...form,
      [id]: e.target.value,
    });
  };

  const handleFileUploadMiniatura = (e: any) => {
    const files = e.target.files;
    if (files) {
      let urlimages = [];
      let filesarray = [];

      for (let i = 0; i < files.length; i++) {
        const file = files[i];
        const url = URL.createObjectURL(file);
        urlimages.push(url);
        filesarray.push(file);
      }
      setLogo(filesarray);
    }
  };

  return (
    <>
      {JSON.stringify(form)}
      <Box>
        <Grid spacing={5} container>
          <Grid item>
            <TextField
              id="email"
              label="email"
              value={form.email}
              onChange={onChangeForm}
            />
          </Grid>
          <Grid item>
            <TextField
              id="senha"
              label="senha"
              value={form.senha}
              onChange={onChangeForm}
            />
          </Grid>
          <Grid item>
            <TextField
              id="color"
              label="color"
              value={form.color}
              onChange={onChangeForm}
            />
          </Grid>
          <Grid item>
            <TextField
              id="logo"
              label=""
              onChange={handleFileUploadMiniatura}
              type="file"
            />
          </Grid>

          <Grid item>
            <TextField
              id="logo"
              label="Config"
              multiline
              onChange={handleFileUploadMiniatura}
            />
          </Grid>
          <IconButton>
            <SendIcon />
          </IconButton>
        </Grid>
      </Box>
    </>
  );
}

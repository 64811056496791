import { useEffect, useState } from "react";
import Appbar from "../../Components/Appbar";
import Content from "../../Components/Content";
import {
  Card,
  CardContent,
  Divider,
  Grid,
  Skeleton,
  Typography,
} from "@mui/material";
import {
  getTotalFipePrice,
  somarNumeroTotalDespesas,
} from "../../Services/CalcFunctions";
import TotalNumeroVendas from "./TotalNumeroVendas";
import PatrimonioFipe from "./PatrimonioFipe";
import { useSelector } from "react-redux";
import { AppDispatch, RootState } from "../../Store";
import dayjs from "dayjs";
import CarrosEmEstoque from "./CarrosEmEstoque";
import LucroBruto from "./LucroBruto";
import { Box } from "@mui/system";
import { VendasChart } from "../../Components/Charts/VendasChart";
import VendedorChart from "../../Components/Charts/VendedorChart";
import { LucroMêsChart } from "../../Components/Charts/LucroMêsChart";
import { useDispatch } from "react-redux";
import {
  fetchFipe,
  fetchPrestadores,
  fetchVehicles,
  fetchVendas,
  fetchVendedores,
} from "../../Store/Veículos";
import { useNavigate } from "react-router-dom";
import MédiaFipe from "./MédiaFipe";
import { Vehicle } from "../../types";
import LucroMédioPorVeículo from "./LucroMédioPorVeículo";

export default function Dashboard() {
  const navigate = useNavigate();
  const dispatch = useDispatch<AppDispatch>();

  const vehiclesSlice = useSelector((state: RootState) => state.vehicles);
  const [fipePrice, setFipePrice] = useState<number>();
  const vendasSlice = useSelector((state: RootState) => state.vendas.data);
  const [vehicles, setVehicles] = useState<Vehicle[]>();

  const [infoVendas, setInfoVendas] = useState<any>({
    numeroVendas: 0,
    numeroVendasLastMonth: 0,
  });

  useEffect(() => {
    setVehicles(vehiclesSlice.data);
  }, [vehiclesSlice]);

  console.log(vehicles);

  useEffect(() => {
    const mesAtual = dayjs(Date.now()).format("MMMM YY");
    const mesPassado = dayjs(Date.now()).add(-1, "month").format("MMMM YY");

    getTotalFipePrice().then((total) => setFipePrice(total));
    const TotalVendasMesAtual = somarNumeroTotalDespesas(mesAtual, vendasSlice);
    const TotalVendasMesPassado = somarNumeroTotalDespesas(
      mesPassado,
      vendasSlice
    );
    setInfoVendas({
      ...infoVendas,
      numeroVendas: TotalVendasMesAtual,
      numeroVendasLastMonth: TotalVendasMesPassado,
    });
  }, []);

  useEffect(() => {
    if (!localStorage.getItem("user")) navigate("/");
    dispatch(fetchFipe());
    dispatch(fetchVehicles());
    dispatch(fetchVendas());
    dispatch(fetchPrestadores());
    dispatch(fetchVendedores());
  }, [dispatch]);

  return (
    <>
      <Appbar />
      <Content>
        <Box>
          <Card sx={{ minWidth: 275 }}>
            <Typography m={2} variant="h5">
              Resumo de Performance
            </Typography>
            <CardContent>
              <Divider />
              <Grid marginLeft={{ xs: "15%", md: "11%", lg: "1%" }} container>
                <Grid
                  display={"flex"}
                  alignItems={"center"}
                  item
                  xs={12}
                  md={6}
                  lg={3}
                >
                  <CarrosEmEstoque vehicles={vehicles} />
                </Grid>
                <Grid
                  display={"flex"}
                  alignItems={"center"}
                  item
                  xs={12}
                  md={6}
                  lg={3}
                >
                  <PatrimonioFipe fipePrice={fipePrice ? fipePrice : 0} />
                </Grid>

                <Grid
                  display={"flex"}
                  alignItems={"center"}
                  item
                  xs={12}
                  md={6}
                  lg={3}
                >
                  <TotalNumeroVendas infoVendas={infoVendas} />
                </Grid>

                <Grid
                  display={"flex"}
                  alignItems={"center"}
                  item
                  xs={12}
                  md={6}
                  lg={3}
                >
                  <LucroBruto />
                </Grid>
              </Grid>

              <Divider />
            </CardContent>
          </Card>
        </Box>
        <Box px={1} mt={5}>
          <Grid container spacing={5}>
            <Grid item xs={12} lg={6}>
              <Card sx={{ minWidth: 275, height: "500px" }}>
                <CardContent sx={{ height: "100%" }}>
                  {vendasSlice.length > 0 ? (
                    <VendasChart vendas={vendasSlice} />
                  ) : (
                    <Skeleton
                      variant="rectangular"
                      width={"100%"}
                      height={"100%"}
                    />
                  )}
                </CardContent>
              </Card>
            </Grid>
            <Grid item xs={12} lg={6}>
              <Card sx={{ minWidth: 275, height: "500px" }}>
                <CardContent sx={{ height: "100%" }}>
                  {vendasSlice.length > 0 ? (
                    <LucroMêsChart vendas={vendasSlice} />
                  ) : (
                    <Skeleton
                      variant="rectangular"
                      width={"100%"}
                      height={"100%"}
                    />
                  )}
                </CardContent>
              </Card>
            </Grid>
          </Grid>
        </Box>
        <Grid px={1} spacing={2} container mt={1}>
          <Grid item xs={12} md={6} lg={3}>
            <MédiaFipe data={vehicles || []} fipePrice={fipePrice || 0} />
          </Grid>
          <Grid item xs={12} md={6} lg={3}>
            <LucroMédioPorVeículo vendas={vendasSlice} />
          </Grid>
        </Grid>
      </Content>
    </>
  );
}

import { Box } from "@mui/material";
import * as React from "react";
import Avatar from "@mui/material/Avatar";
import Button from "@mui/material/Button";
import CssBaseline from "@mui/material/CssBaseline";
import TextField from "@mui/material/TextField";
import Link from "@mui/material/Link";
import LockOutlinedIcon from "@mui/icons-material/LockOutlined";
import Typography from "@mui/material/Typography";
import Container from "@mui/material/Container";
import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";
import { verifyLogin } from "./Services/Login";
import FirtAccessModal from "./Components/ModalFirstAccess";
import { Alert, LoadingButton } from "@mui/lab";
import { useNavigate } from "react-router-dom";
import logo from "./mgclogonovo.png";

function Copyright(props: any) {
  return (
    <Typography
      variant="body2"
      color="text.secondary"
      align="center"
      {...props}
    >
      {"Copyright © "}
      <Link color="inherit" href="https://mui.com/">
        ManagementCars
      </Link>{" "}
      {new Date().getFullYear()}
      {"."}
    </Typography>
  );
}

interface form {
  email: string;
  password: string;
}
export default function App() {
  const navigate = useNavigate();
  const [form, setForm] = React.useState<form>({ email: "", password: "" });
  const [error, setError] = React.useState<string>();
  const [newUser, setnewUser] = React.useState<string>();
  const [loading, setLoading] = React.useState<boolean>(false);
  const handleChange = (e: any) => {
    const id = e.target.id;
    setForm({
      ...form,
      [id]: e.target.value,
    });
  };

  async function onClickEntrar() {
    setnewUser("");
    setLoading(false);
    setError("");
    setLoading(true);
    const verificação = await verifyLogin(form.email, form.password);
    if (
      verificação === "Usuário ou Senha incorreta" ||
      verificação ===
        "Erro interno de servidor. Entre em contato com o suporte."
    ) {
      setLoading(false);
      setError(verificação);
    } else if (
      verificação.result === "login" ||
      verificação.result === "loginFunc"
    ) {
      localStorage.setItem("color", verificação.user.color);
      localStorage.setItem("logo", verificação.user.logo);
      localStorage.setItem("user", btoa(verificação.user.key));
      verificação.result === "loginFunc"
        ? localStorage.setItem("func", "true")
        : localStorage.setItem("func", "false");
      navigate("/cars");
      window.location.reload();
      setError("");
      setLoading(false);
      ("");
    } else {
      setError("");
      setLoading(false);
      setnewUser(verificação);
    }
  }

  React.useEffect(() => {
    if (localStorage.getItem("user")) {
      navigate("/cars");
    }
  }, []);

  return (
    <Box width={"100vw"} height={"100vh"} display={"flex"}>
      <Container component="main" maxWidth="xs">
        <CssBaseline />
        <Box
          sx={{
            marginTop: 8,
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <img height={50} src={logo} alt="" />
          <Typography mt={2} component="h1" variant="h5">
            Login
          </Typography>
          <Box component="form" noValidate sx={{ mt: 1 }}>
            <TextField
              onChange={handleChange}
              margin="normal"
              required
              fullWidth
              id="email"
              label="Email"
              name="email"
              autoComplete="email"
              autoFocus
            />
            <TextField
              onChange={handleChange}
              margin="normal"
              required
              fullWidth
              name="password"
              label="Senha"
              type="password"
              id="password"
              autoComplete="current-password"
            />
            <FormControlLabel
              control={<Checkbox value="remember" color="default" />}
              label="Permanecer Conectado"
            />
            <LoadingButton
              loading={loading}
              fullWidth
              variant="contained"
              onClick={onClickEntrar}
              sx={{
                mt: 3,
                mb: 2,
                backgroundColor: "rgb(12, 74, 242)",
                "&:hover": {
                  backgroundColor: "rgb(27, 190, 209)",
                },
              }}
            >
              Entrar
            </LoadingButton>
            {error ? <Alert severity="error">{error}</Alert> : <></>}

            <Copyright sx={{ mt: 2, mb: 4 }} />
          </Box>
        </Box>
        {JSON.stringify(newUser)}
        {newUser ? <FirtAccessModal login={newUser} /> : <></>}
      </Container>
    </Box>
  );
}

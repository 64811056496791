import * as React from "react";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import CardMedia from "@mui/material/CardMedia";
import Typography from "@mui/material/Typography";
import { Button, CardActionArea, CardActions } from "@mui/material";
import { Despesa, Vehicle } from "../../types";
import { useNavigate } from "react-router-dom";
import { toNumber, toReais } from "../../Services/AuxiliarFunctions";

interface CardVehicle {
  data: Vehicle;
}

export default function CardVehicle(props: CardVehicle) {
  React.useEffect(() => {
    let somaa = 0;
    for (let key in props.data.despesas) {
      somaa += Number(props.data.despesas[key].valor);
    }
    setsoma(somaa);
  }, [props]);

  const [soma, setsoma] = React.useState(0);

  const navigate = useNavigate();

  function onClickCard() {
    return navigate(`/cars/${props.data.placa}`);
  }
  return (
    <>
      {props.data ? (
        <Card
          sx={{
            p: 1,
          }}
          onClick={onClickCard}
        >
          <CardActionArea
            sx={{
              padding: "5px",
            }}
          >
            {props.data.fotos ? (
              <CardMedia
                sx={{
                  objectPosition: "40% 100%",
                  objectFit: "cover",
                  borderRadius: "3%",
                  width: "100%",
                  height: "250px",
                }}
                component="img"
                image={props.data.fotos[0]}
              />
            ) : (
              <CardMedia
                sx={{
                  borderRadius: "3%",
                  width: "100%",
                  height: "250px",
                }}
                component="img"
                image={
                  "https://patrik.luciluci.com.br/public/images/sem-imagem.png"
                }
              />
            )}

            <CardContent
              sx={{
                p: 1,
              }}
            >
              <Typography
                fontWeight={"bold"}
                height={70}
                overflow={"hidden"}
                gutterBottom
                variant="h6"
                component="div"
              >
                {props.data.fipeInfo.model}
              </Typography>

              <Typography whiteSpace={"nowrap"} color="text.secondary">
                Ano:{" "}
                <span style={{ fontWeight: "bold" }}>
                  {" "}
                  {props.data.ano.substr(0, 4)}
                </span>{" "}
                &nbsp; Cor:{" "}
                <span style={{ fontWeight: "bold" }}>{props.data.cor}</span>
                <br /> Placa:{" "}
                <span style={{ fontWeight: "bold" }}>{props.data.placa}</span>
                <br /> Fipe:{" "}
                <strong style={{ color: "rgb(10, 10, 114)" }}>
                  {" "}
                  {props.data.fipeInfo.price}{" "}
                </strong>
                <br /> Despesas:{" "}
                <strong style={{ color: "red" }}> {toReais(soma)} </strong>
                <br /> Limite:{" "}
                <strong style={{ color: "rgb(190, 130, 0)" }}>
                  {" "}
                  {toReais(
                    ((toNumber(props.data.valorCompra) + soma) * 1.1).toFixed(2)
                  )}{" "}
                </strong>
                <br /> Preço:{" "}
                <strong style={{ color: "green" }}>
                  {" "}
                  {props.data.valorVenda}{" "}
                </strong>
              </Typography>
            </CardContent>
          </CardActionArea>
        </Card>
      ) : (
        <></>
      )}
    </>
  );
}

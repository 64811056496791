import { Vehicle } from "../types";
import { getFipeInfoByCode } from "./Fipe";
import { updateVehicle } from "./FirebaseDatabase";

export function formatDate(dateString: string) {
  let date = new Date(dateString);
  let day = date.getDate().toString().padStart(2, "0");
  let month = (date.getMonth() + 1).toString().padStart(2, "0");
  let year = date.getFullYear();

  return `${day}/${month}/${year}`;
}

export function toReais(valor: string | number) {
  return Number(valor).toLocaleString("pt-BR", {
    style: "currency",
    currency: "BRL",
  });
}

export function toNumber(valor: string) {
  return Number(valor.replace("R$", "").split(",")[0].replace(".", ""));
}

export function formatNumberToK(num: number): string {
  if (num > 1000000) {
    return (num / 1000000).toFixed(2) + "M";
  } else if (num > 100000) {
    return (num / 1000).toFixed(0) + "k";
  } else if (num >= 10000) {
    return (num / 1000).toFixed(1) + "k";
  } else if (num >= 100) {
    return (num / 1000).toFixed(2).replace(".", ",") + "k";
  } else if (num >= 10) {
    return num.toFixed(1).replace(".", ",") + "k";
  } else {
    return num.toString();
  }
}

export async function webpToJpeg(base64: string): Promise<string> {
  return new Promise<string>((resolve, reject) => {
    const canvas = document.createElement("canvas");
    const context = canvas.getContext("2d");
    if (!context) {
      reject("Não foi possível converter a imagem");
      return;
    }

    const image = new Image();
    image.onload = () => {
      canvas.width = image.width;
      canvas.height = image.height;
      context.drawImage(image, 0, 0);

      const jpegBase64 = canvas.toDataURL("image/jpeg", 1.0);
      resolve(jpegBase64);
    };
    image.onerror = () => {
      reject("Não foi possível carregar a imagem");
    };
    image.src = base64;
  });
}

export async function AttPreçosFipe(data: Vehicle[]) {
  await Promise.all(
    data.map(async (veiculo) => {
      const fipeInfo = await getFipeInfoByCode(
        veiculo.fipeInfo.codeFipe,
        veiculo.ano
      );
      let newpriceHistory = [];
      if (veiculo.priceHistory) {
        newpriceHistory = veiculo.priceHistory.map((x: any) => x);
      }
      if (
        !newpriceHistory.some(
          (h: any) => h.month === fipeInfo.data.referenceMonth
        )
      ) {
        newpriceHistory.push({
          month: fipeInfo.data.month,
          price: fipeInfo.data.price,
          reference: "unknown",
        });
      }
      const newVehicle: Vehicle = {
        ...veiculo,
        fipeInfo: {
          ...veiculo.fipeInfo,
          price: fipeInfo.data.price,
        },
        priceHistory: newpriceHistory,
      };
      await updateVehicle(newVehicle);
    })
  );
  return true;
}

import Typography from "@mui/material/Typography";
import { Box } from "@mui/material";
import {
  formatNumberToK,
  toNumber,
  toReais,
} from "../../Services/AuxiliarFunctions";
import Avatar from "@mui/material/Avatar";
import MonetizationOnIcon from "@mui/icons-material/MonetizationOn";
import { useEffect, useState } from "react";
import { RootState } from "../../Store";
import { useSelector } from "react-redux";
import { Venda } from "../../types";
import dayjs from "dayjs";

export default function LucroBruto() {
  const [value, setValue] = useState(0);
  const [tempoAnimacao] = useState(2000);

  const vendasSlice: Venda[] = useSelector(
    (state: RootState) => state.vendas.data
  );

  useEffect(() => {
    let soma = 0;
    const mesAtual = dayjs(Date.now()).format("MMMM YY");
    const vendaFilter = vendasSlice.filter(
      (v) => dayjs(v.data).format("MMMM YY") === mesAtual
    );
    vendaFilter.forEach((venda) => {
      let somaDespesas = 0;
      for (let key in venda.vehicle.despesas) {
        somaDespesas += Number(venda.vehicle.despesas[key].valor);
      }
      somaDespesas += toNumber(venda.vehicle.valorCompra);
      let faturamento = Number(venda.valor);
      if (venda.ValorCompraTroca) {
        faturamento += Number(venda.ValorCompraTroca);
      }
      soma += faturamento - somaDespesas;
    });

    const incremento = Math.ceil(soma / (tempoAnimacao / 10));
    const intervalo = setInterval(() => {
      setValue((numeroAntigo) => {
        const diferenca = soma - numeroAntigo;
        const porcentagem = diferenca / soma;
        let novoIncremento = incremento;
        if (porcentagem <= 0.1) {
          novoIncremento = Math.ceil(incremento / 4);
        }
        if (numeroAntigo < soma) {
          return numeroAntigo + novoIncremento;
        }
        clearInterval(intervalo);
        return soma;
      });
    }, 1);
    return () => clearInterval(intervalo);
  }, []);
  return (
    <Box alignItems={"center"} display={"flex"} padding={1}>
      <Avatar
        sx={{ width: 70, height: 70, backgroundColor: "rgb(84, 204, 138)" }}
      >
        <MonetizationOnIcon fontSize="large" />
      </Avatar>
      <Box>
        <Typography fontFamily={"sans-serif"} color={"grey"} mx={2}>
          Lucro Bruto do Mês
        </Typography>
        <Typography
          fontFamily={"monospace"}
          variant="h3"
          color={"black"}
          mx={2}
        >
          <strong>{formatNumberToK(value)}</strong>
        </Typography>
      </Box>
    </Box>
  );
}
